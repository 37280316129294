<template>
    <ValidationObserver class="needs-validation form-custom-format " v-bind:class="{'was-validated' : validated}" tag="form" ref="observer" v-slot="{passes}" @submit.prevent="onSubmitForm()">
        <el-dialog :modal="true" ref="window" :fullscreen="true" :title="'Update Quantity'" :visible.sync="syncModal" :close-on-click-modal="false" :modal-append-to-body="true" :append-to-body="true" class="text-left bulk-update-qty-dialog" :formStatus="formStatus" @dialog-state="dialogState" :group="group" :showClose="!processing">
            <template v-if="formStatus == 'select'">
                <el-row :gutter="20">
                    <el-col :span="12" :offset="6" class="mt-4 mb-2">
                        <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange" border size="medium">CHECK ALL</el-checkbox>
                    </el-col>
                    <el-checkbox-group v-model="checkedOptions" @change="handleCheckedOptionsChange">
                        <el-col :span="12" :offset="6" v-for="obj in checkboxOptions" class="mt-1" :key="obj.value">
                            <el-checkbox :label="obj.value" :key="obj.value" border size="medium">{{obj.label.toUpperCase()}}</el-checkbox>
                        </el-col>
                    </el-checkbox-group>
                </el-row>
            </template>
            <div ref="spreadsheet"></div>
            <template #footer>
                <div class="d-flex">
                    <template v-if="formLoading">
                        <mdb-btn color="elegant" size="sm" disabled>
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            Loading...
                        </mdb-btn>
                    </template>
                    <template v-else>
                        <template v-if="formStatus == 'select'">

                            <template v-if="enableButton == true">
                                <mdb-btn color="elegant" size="sm" disabled>
                                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    Please wait...Getting dependencies from server
                                </mdb-btn>
                            </template>
                            <template v-else>
                                <mdb-btn color="elegant" size="sm" @click="passes(onSubmitSelectForm)" :disabled="!checkedOptionsComputed"><i class="el-icon-right text-white cursor-pointer"></i> Next</mdb-btn>
                            </template>
                        </template>
                        <template v-if="formStatus == 'create'">

                            <template v-if="processing == true">
                                <mdb-btn color="elegant" size="sm" disabled>
                                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    Processing...
                                </mdb-btn>
                            </template>
                            <template v-else>
                                <mdb-btn color="elegant" size="sm" @click="passes(onSubmitForm)"><i class="el-icon-check text-white cursor-pointer"></i> Save</mdb-btn>
                            </template>
                            <mdb-btn color="grey" size="sm" @click="passes(clearForm)"><i class="el-icon-copy-document text-white cursor-pointer"></i> Clear</mdb-btn>
                            <mdb-btn color="grey" size="sm" @click="passes(onSubmitCopyTemplate)"><i class="el-icon-copy-document text-white cursor-pointer"></i> Copy Template</mdb-btn>
                            <mdb-btn color="grey" size="sm" @click="passes(onSubmitBack)"><i class="el-icon-back text-white cursor-pointer"></i> Back</mdb-btn>
                        </template>
                        <mdb-btn color="grey" size="sm" @click="closeDialog"><i class="el-icon-close text-white cursor-pointer"></i> Close</mdb-btn>
                    </template>
                </div>
            </template>
        </el-dialog>
    </ValidationObserver>
</template>
<script>
import { mdbBtn,mdbContainer} from "mdbvue";
import {ValidationObserver, ValidationProvider} from "vee-validate";
import { mapActions,mapGetters, mapMutations } from 'vuex'

import {  mdbRow,mdbCol } from 'mdbvue';
import jspreadsheet from '../../jsexcel/jexcel'
import { drop } from "lodash";

import { MessageBox, Message } from "element-ui";

import  {ThatsDialog} from '../../components/thats/Thats'

const dropdownBrandFilter = function(instance, cell, c, r, source) {

    console.log('source',source)
    var value = instance.jexcel.getValueFromCoords(c - 1, r);
    const filterResult = source.filter((item)=>{
        return item.parent == value
    },[]);
    return filterResult
}


const dropdownCategoryFilter = function(instance, cell, c, r, source) {

    console.log('source',source)
    var value = instance.jexcel.getValueFromCoords(c - 1, r);
    const filterResult = source.filter((item)=>{
        return item.area == value
    },[]);
    return filterResult
}

export default {
    components: { ValidationProvider,
        mdbBtn,
        ValidationObserver,
        mdbRow,
        mdbCol,
        ThatsDialog
    },
    props : {
        group : {
            type : Number,
            default : 1
        },
    },

    inject:[],
    data : () => ({
        syncModal : false,
        validated: false,
        formStatus : "select",
        form : null,
        formLoading : false,
        processing : false,
        showError : false,
        group2 : 3,
        spreadsheet: null,
        exportTableOptions : {
            download : false,
            allowDeleteColumn : false,
            allowRenameColumn : false,
            defaultColAlign : 'left',
            csvHeader:true,
            tableWidth : '100%',
            tableHeight : 'calc(100vh - 85px)',
            tableOverflow : true,
            data:[[]],
            minDimensions:[3,50],
            onCopy : null,
            selectionCopy : false,
            allowCopy : true,
            columns: [

                {
                    type: 'text',
                    width: '200px',
                    title: 'Series',
                    name: 'series',
                    readOnly: false,
                    primaryKey: false
                },
                {
                    type: 'text',
                    width: '200px',
                    title: 'Item Name',
                    name: 'name',
                    readOnly: false,
                },
                {
                    type: 'text',
                    width: '100px',
                    title: 'Opening Qty',
                    name: 'qty',
                    readOnly: false,
                },

                {
                    type: 'text',
                    width: '100px',
                    title: 'Stock',
                    name: 'stock_qty',
                    readOnly: false,
                },

                {
                    type: 'text',
                    width: '100px',
                    title: 'Sold',
                    name: 'sold_qty',

                },
                {
                    type: 'text',
                    width: '100px',
                    title: 'In Transit',
                    name: 'in_transit_qty',

                },
                {
                    type: 'text',
                    width: '100px',
                    title: 'Under Prd.',
                    name: 'under_production_qty',

                },
                // {
                //     type: 'text',
                //     width: '200px',
                //     title: 'Dimension Type',
                //     name: 'dimension_type',
                //
                // },
                {
                    type: 'text',
                    width: '200px',
                    title: 'Dimension',
                    name: 'dimension',

                },
                {
                    type: 'text',
                    width: '340px',
                    title: 'Description.',
                    name: 'description',

                },
                {
                    type: 'text',
                    width: '340px',
                    title: 'Response',
                    name: 'message',
                },


            ],

            contextMenu: function(obj, x, y, e) {

                return false;
            }
        },

        isIndeterminate: false,
        checkAll: true,
        checkedOptions: ['name','opening_qty', 'stock_qty', 'sold_qty', 'in_transit_qty', 'under_production_qty', 'dimension','dimension_type','description','group', 'brand', 'area', 'category'],
        checkboxOptions : [
            { label: 'Item Name', value: 'name', width : '200px', dropdown : false, type : 'text'},
            { label: 'Opening Qty', value: 'opening_qty', width : '100px', dropdown : false, type : 'text'},
            { label: 'Stock', value: 'stock_qty', width : '100px', dropdown : false, type : 'text'},
            { label: 'Sold', value: 'sold_qty', width : '100px', dropdown : false, type : 'text'},
            { label: 'In Transit', value: 'in_transit_qty', width : '100px', dropdown : false, type : 'text' },
            { label: 'Under Production', value: 'under_production_qty', width : '100px', dropdown : false, type : 'text' },
            { label: 'Dimension Type', value: 'dimension_type', width : '100px', dropdown: true, type : 'dropdown', source : [
                    { name: 'Size', id: 'size' },
                    { name: 'WxHxD', id: 'whd' }
                ]},
            { label: 'Dimension', value: 'dimension', width : '100px', dropdown : false, type : 'text' },
            { label: 'Description', value: 'description', width : '100px', dropdown : false, type : 'text' },
            { label: 'Group', value: 'group', width : '100px', dropdown: true, type : 'dropdown', source : 'groupComputed'},
            { label: 'Brand', value: 'brand', width : '100px', dropdown: true, type : 'dropdown', source : 'brandComputed'},
            { label: 'Area', value: 'area', width : '100px', dropdown: true, type : 'dropdown', source : 'areaComputed'},
            { label: 'Category', value: 'category', width : '100px', dropdown: true, type : 'dropdown', source : 'categoryComputed'},
            { label: 'Item Status', value: 'status', width : '100px', dropdown: true, type : 'dropdown', source : [
                    {name : "Archive", id : "Archive"},
                    {name : "Active", id : "Active"},
                    {name : "Discontinued", id : "Discontinued"},
                ]},
            { label: 'Item Type', value: 'item_type', width : '150px', dropdown: true, type : 'dropdown', source : [
                    {name : "Stock", id : "Stock"},
                    {name : "Service", id : "Service"},
                ]},
            { label: 'Web Live Status', value: 'website', width : '150px', dropdown: true, type : 'dropdown', source : [
                    {name : "Yes", id : "Yes"},
                    {name : "No", id : "No"},
                ]},
            { label: 'Price', value: 'sale_price', width : '150px', dropdown: false, type : 'numeric'},
            { label: 'PurchaseUomDimension', value: 'PurchaseUomDimension', width : '150px', dropdown: false, type : 'text'},
            { label: 'SalesUomDimension', value: 'SalesUomDimension', width : '150px', dropdown: false, type : 'text'},
            
            /* { label: 'PurchaseDepth/Length', value: 'PurchaseUnitLength', width : '150px', dropdown: false, type : 'numeric'},
            { label: 'PurchaseUnitWidth', value: 'PurchaseUnitWidth', width : '150px', dropdown: false, type : 'numeric'},
            { label: 'PurchaseUnitHeight', value: 'PurchaseUnitHeight', width : '150px', dropdown: false, type : 'numeric'},
            { label: 'PurchaseUnitVolume', value: 'PurchaseUnitVolume', width : '150px', dropdown: false, type : 'numeric',readOnly : true},
            { label: 'PurchaseUnitWeight', value: 'PurchaseUnitWeight', width : '150px', dropdown: false, type : 'numeric'},
            { label: 'SalesDepth/Length', value: 'SalesUnitLength', width : '150px', dropdown: false, type : 'numeric'},
            { label: 'SalesUnitWidth', value: 'SalesUnitWidth', width : '150px', dropdown: false, type : 'numeric'},
            { label: 'SalesUnitHeight', value: 'SalesUnitHeight', width : '150px', dropdown: false, type : 'numeric'},
            { label: 'SalesUnitVolume', value: 'SalesUnitVolume', width : '150px', dropdown: false, type : 'numeric',readOnly : true},
            { label: 'SalesUnitWeight', value: 'SalesUnitWeight', width : '150px', dropdown: false, type : 'numeric'}, */
        ],

        postedData : [],



    }),
    inject : ['brandProvider','categoryProvider','areaProvider','collectionProvider'],
    mounted() {

    },
    methods: {
        ...mapActions('InventoryItemMasterModule',['bulkItemUpdate']),
        createPopupTable(exportTableOptions){
            this.spreadsheet = jspreadsheet(this.$refs.spreadsheet, exportTableOptions);
            Object.assign(this, this.spreadsheet);
            this.spreadsheet.setData([])
        },

        async recreateSpreadsheet(){
            await this.spreadsheet.destroy();
        },
        /* start */



        optionsHandler(){
            var self = this;
        },



        /* end */

        openDialog(){
            this.syncModal = true
            this.formResetter();
            this.formStatus = 'select'
            /* */
        },
        closeDialog(){
            this.syncModal = false
            this.formResetter();
        },

        onSubmitCopyTemplate(){
            const self = this;

            const selectedColumns = this.checkboxOptions.filter((item) => {
                return self.checkedOptions.includes(item.value)
            },[])

            var txt = "Series\t";
            if(selectedColumns.length > 0){
                selectedColumns.forEach(element => {
                    txt += element.label+"\t"
                });
                txt += "\n"
            }

            var dummy = document.createElement("textarea");
            document.body.appendChild(dummy);
            dummy.setAttribute("id", "dummy_id");
            document.getElementById("dummy_id").value=txt;
            dummy.select();
            document.execCommand("copy");
            document.body.removeChild(dummy);

            Message.success({
                message: "Copied!"
            });
        },

        onSubmitSelectForm(){
            const self = this;

            this.formResetter();

            this.formStatus = 'create';
            let tableCols = this.checkboxOptions.filter((obj) => {
                return this.checkedOptions.includes(obj.value)
            },[]).map((obj) => {
                const dropdownObject = {}
                const numericObject = {}



                if(obj.hasOwnProperty('dropdown') && obj.dropdown == true){
                    const fromComputed = ['brandComputed','categoryComputed','areaComputed','groupComputed'];
                    dropdownObject.source = fromComputed.includes(obj.source) ? this[`${obj.source}`] : obj.source;
                    dropdownObject.autocomplete = true;
                    dropdownObject.multiple = false;
                    if(obj.source == 'brandComputed'){
                        dropdownObject.filter = dropdownBrandFilter
                    }
                    if(obj.source == 'categoryComputed'){
                        dropdownObject.filter = dropdownCategoryFilter
                    }

                }

                if(obj.type == 'numeric'){
                    numericObject.mask = '#,##.00';
                    numericObject.width = 100;
                    numericObject.decimal = '.';
                }
                return {
                    type: obj.type,
                    width: obj.width,
                    title: obj.label,
                    name: obj.value,
                    readOnly: false,
                    ...dropdownObject,
                    ...numericObject
                }
            });

           /*  [{ label: 'PurchaseDepth/Length', value: 'PurchaseUnitLength', width : '150px', dropdown: false, type : 'numeric'},
            { label: 'PurchaseUnitWidth', value: 'PurchaseUnitWidth', width : '150px', dropdown: false, type : 'numeric'},
            { label: 'PurchaseUnitHeight', value: 'PurchaseUnitHeight', width : '150px', dropdown: false, type : 'numeric'},
            { label: 'PurchaseUnitVolume', value: 'PurchaseUnitVolume', width : '150px', dropdown: false, type : 'numeric',readOnly : true},
            { label: 'PurchaseUnitWeight', value: 'PurchaseUnitWeight', width : '150px', dropdown: false, type : 'numeric'},
            { label: 'SalesDepth/Length', value: 'SalesUnitLength', width : '150px', dropdown: false, type : 'numeric'},
            { label: 'SalesUnitWidth', value: 'SalesUnitWidth', width : '150px', dropdown: false, type : 'numeric'},
            { label: 'SalesUnitHeight', value: 'SalesUnitHeight', width : '150px', dropdown: false, type : 'numeric'},
            { label: 'SalesUnitVolume', value: 'SalesUnitVolume', width : '150px', dropdown: false, type : 'numeric',readOnly : true},
            { label: 'SalesUnitWeight', value: 'SalesUnitWeight', width : '150px', dropdown: false, type : 'numeric'},] */


            const hasPurchaseColumn = tableCols.filter((i) => i.name === 'PurchaseUomDimension',[]);
            
            if(hasPurchaseColumn.length > 0){
                tableCols = [...tableCols,
                        { title: 'PurchaseLength', name: 'PurchaseUnitLength', width : '150px', dropdown: false, type : 'numeric'},
                        { title: 'PurchaseUnitWidth', name: 'PurchaseUnitWidth', width : '150px', dropdown: false, type : 'numeric'},
                        { title: 'PurchaseUnitHeight', name: 'PurchaseUnitHeight', width : '150px', dropdown: false, type : 'numeric'},
                        { title: 'PurchaseUnitVolume', name: 'PurchaseUnitVolume', width : '150px', dropdown: false, type : 'numeric',readOnly : true},
                        { title: 'PurchaseUnitWeight', name: 'PurchaseUnitWeight', width : '150px', dropdown: false, type : 'numeric'}
                    ]
            }

       

            const hasSalesColumn = tableCols.filter((i) => i.name === 'SalesUomDimension',[]);
            if(hasSalesColumn.length > 0){
                tableCols = [...tableCols,
                    { title: 'SalesLength', name: 'SalesUnitLength', width : '150px', dropdown: false, type : 'numeric'},
                    { title: 'SalesUnitWidth', name: 'SalesUnitWidth', width : '150px', dropdown: false, type : 'numeric'},
                    { title: 'SalesUnitHeight', name: 'SalesUnitHeight', width : '150px', dropdown: false, type : 'numeric'},
                    { title: 'SalesUnitVolume', name: 'SalesUnitVolume', width : '150px', dropdown: false, type : 'numeric',readOnly : true},
                    { title: 'SalesUnitWeight', name: 'SalesUnitWeight', width : '150px', dropdown: false, type : 'numeric'}
                ]
            }

            const filteredColumns = tableCols.filter((i) =>  i.name !== 'SalesUomDimension' && i.name !== 'PurchaseUomDimension',[]);
            
            
            const finalColumns = [
                { type: 'text', width: '200px', title: 'Series', name: 'series', readOnly: false, primaryKey: false },
                ...filteredColumns,
                { type: 'text', width: '340px', title: 'Response', name: 'message', readOnly: false },
            ]


            this.$nextTick(() => {
                const width = this.$refs.window.width;
                this.exportTableOptions.tableWidth = "100%";
                this.exportTableOptions.columns = finalColumns;
                this.createPopupTable(this.exportTableOptions)
            });

        },

        onSubmitBack(){
            const self = this;
            MessageBox.confirm('Are you sure you want to go back?', 'Warning', {
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                type: 'warning'
            }).then(() => {

                self.formStatus = 'select';
                self.form = self.formVar()
                requestAnimationFrame(() => {
                    self.$refs.observer.reset();
                });
                self.validated = false
                self.formLoading = false
                if(self.spreadsheet){
                    self.spreadsheet.destroy();
                }

            }).catch(() => {
                return
            });
        },

        onSubmitForm(){

            const self = this;

            if(this.processing == true){
                return false
            }
            const tableData = this.spreadsheet.getJson();

            MessageBox.confirm('Warning : Existing data will be overwrite. Do you want to continue?', 'Warning', {
                confirmButtonText: 'OK',
                cancelButtonText: 'Cancel',
                type: 'warning'
            }).then(() => {
                if(tableData.length > 0){

                    this.postCounter = 0
                    this.dataLength = tableData.filter((item) => item.series !== '',[]).length;
                    if(this.dataLength > 0){

                        console.log("tableData",tableData)

                        const validData = tableData.filter((item) => item.series != '',[]).map((item) => {
                            return {...item, completed : false}
                        },[])
                        if(validData.length == 0){
                            self.$ThatsNotify.warning({
                                message : `Please fill up the series column.`,
                                timeOut : 10000
                            })
                            return false
                        }

                        console.log('dataLength',validData)

                        let checkedOptionsLength = self.checkedOptions.length;

                        console.log("self.checkedOptions",self.checkedOptions)

                         if(self.checkedOptions.includes("SalesUomDimension")){
                            checkedOptionsLength=checkedOptionsLength +4;
                             console.log("checkedOptionsLengthY",checkedOptionsLength)
                         }


                          if(self.checkedOptions.includes("PurchaseUomDimension")){
                            checkedOptionsLength=checkedOptionsLength +4;
                             console.log("checkedOptionsLengthX",checkedOptionsLength)
                         }

                        console.log("checkedOptionsLength",checkedOptionsLength)

                        const columns = self.iterateAlphabetByNumber((checkedOptionsLength + 2))
                        self.postedData = JSON.parse(JSON.stringify(validData));
                        validData.forEach((element, forIndex) => {
                            const row = 1 + forIndex
                            self.processing = true;

                            columns.forEach((col) => {
                                self.spreadsheet.setStyle(`${col}${row}`,'background-color','#fff',true);
                            })

                            self.spreadsheet.setValue(`${columns[columns.length - 1]}${row}`,"Please wait...");

                            self.bulkItemUpdate({...element,index : row}).then((response) => {
                                const i = parseInt(response.index);
                                columns.forEach((col) => {
                                    self.spreadsheet.setStyle(`${col}${i}`,'background-color','#00c851',true);
                                })
                                self.spreadsheet.setValue(`${columns[columns.length - 1]}${i}`,response.message);
                                self.postCounter++
                                self.$set(self.postedData[i-1],'completed',true)
                            }).catch((error) => {
                                const i = parseInt(error.response.data.index)
                                columns.forEach((col) => {
                                    self.spreadsheet.setStyle(`${col}${i}`,'background-color','#fb3',true);
                                })
                                self.spreadsheet.setValue(`${columns[columns.length - 1]}${i}`,error.response.data.message);
                                this.postCounter++;
                                self.$set(self.postedData[i-1],'completed',true)
                            });
                        });
                    }
                }
            })

        },
        formResetter(){
            this.form = this.formVar()
            requestAnimationFrame(() => {
                this.$refs.observer.reset();
            });
            this.validated = false
            this.formLoading = false
            this.processing = false
            this.setFormStatusCreate();

            if(this.spreadsheet){
                this.spreadsheet.destroy();
            }
        },

        dialogState(state){
            this.optionsHandler();
            if(!state){
                this.formResetter();
                return
            }
            if(this.FormStatus == 'create'){
                this.formResetter();
            }

        },

        formVar(){
            return {
                items : [],
            }
        },
        setFormStatusCreate(){
            this.formStatus = 'create'
        },

        handleCheckAllChange(val) {
            this.checkedOptions = val ? this.checkboxOptions.map(i => i.value,[]) : [];
            this.isIndeterminate = false;
        },
        handleCheckedOptionsChange(value) {
            let checkedCount = value.length;
            this.checkAll = checkedCount === this.checkboxOptions.length;
            this.isIndeterminate = checkedCount > 0 && checkedCount < this.checkboxOptions.length;
        },

        iterateAlphabetByNumber(numColumns) {
            const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
            const columns = [];

            for (let i = 0; i < numColumns; i++) {
                let label = '';
                let index = i;
                while (index >= 0) {
                    label = alphabet[index % 26] + label;
                    index = Math.floor(index / 26) - 1;
                }

                columns.push(label);
            }
            return columns;
        },
        clearForm(){
            this.recreateSpreadsheet();
            this.createPopupTable(this.exportTableOptions);
        },
    },

    computed: {

        checkedOptionsComputed : function(){
            return (this.checkedOptions.length > 0) ? true : false;
        },

        brandComputed : function(){
            const brandProvider =  this.brandProvider();

            return brandProvider.map((item) => {
                const name = `${item.code} - ${item.brand}`
                return {...item,name : name.toUpperCase(),id : item.code}
            },[])
        },
        groupComputed : function(){
            const brandProviderData = this.brandProvider();
            return brandProviderData.filter((item) => item.parent == 0,[]).map((item) => {
                const name = `${item.code} - ${item.brand}`
                return {...item,name : name.toUpperCase(),id : item.code}
            },[])
        },

        categoryComputed : function(){
            return this.categoryProvider().map((item) => {
                const name = `${item.code} - ${item.category}`
                return {...item,name : name.toUpperCase(),id : item.code}
            },[])
        },
        areaComputed : function(){
            return this.areaProvider().map((item) => {
                const name = `${item.code} - ${item.area}`
                return {...item,name : name.toUpperCase(),id : item.code}
            },[])
        },

        collectionComputed : function(){
            return this.collectionProvider();
        },

        enableButton : function(){
            return (this.areaComputed.length > 0 && this.brandComputed.length > 0 && this.categoryComputed.length > 0) ? false : true;
        },

        isAllCompleted : function(){
            return (this.postedData.length > 0) ? this.postedData.every((item) => item.completed == true) : false;
        }
    },

    watch: {

        brandComputed : function(v){
            this.filteredBrands = v
        },

        isAllCompleted : function(value){
            if(value == true){
                this.processing = false;
                this.$emit('success');
            }
        }
    },


}
</script>

<style scoped>
.custom-checkbox .custom-control-label::before {
    border-radius: .50rem!important;cursor: pointer;
}

.no-image-gallery i.far {
    font-size: 42px;
    color: #959595;
}
.mdb-time-picker .clockpicker-span-hours,
.mdb-time-picker .clockpicker-span-minutes {
    color: #4285f4 !important;
}


</style>
