import unformat from 'accounting-js/lib/unformat.js'
import formatMoney from 'accounting-js/lib/formatMoney.js'

export const utils = {
    formatMoney : (amount, params = { symbol: "", precision: 2, thousand: ",", decimal: "." }) =>{
        return formatMoney(amount, params);
    },
    unformat : (amount) =>{
        return unformat(amount);
    },
    
}
