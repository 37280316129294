import { netherlandsHttpService } from '../../services'
export const CartModule = {
    namespaced: true,
    state: {

        loading: false,
        cart: [],
        count: 0,
        total: 0,
        request: null,
        postLoading : false,
        getLoading : false,
        updateLoading : false,
        deleteLoading : false,
        createCartLoading : false
    },
    actions: {

        async getCart({ state, commit }, params = {}) {
            const configParams = {
                req : "CartModule/req",
                loadingTrue : "CartModule/getLoadingTrue",
                addRequest : "CartModule/addRequest",
                cancelRequest : "CartModule/cancelRequest",
                clearOldRequest : "CartModule/clearOldRequest"
            }
            return await new Promise((resolve, reject) => {
                const { getLoading } = state
                if (!getLoading) {
                    commit('getLoadingTrue')
                    let user = JSON.parse(localStorage.getItem('user'));            
                    netherlandsHttpService.get(`cart/${user.cartData.cartKey}`, params, configParams).then((response) => {
                        const cartData = response.data.data.map((item) => {
                            return {
                                ...item
                            }
                        },[])
                        commit('setCart', cartData)
                        commit('getLoadingFalse')
                        resolve(response.data.data);
                    }).catch(error => {
                        commit('getLoadingFalse')
                        commit('clearOldRequest')
                        reject(error)
                    })
                }
            })
        },

        async placeOrder({state,commit, dispatch}, payload) {
            const configParams = {
                req : "CartModule/req",
                loadingTrue : "CartModule/loadingTrue",
                addRequest : "CartModule/addRequest",
                cancelRequest : "CartModule/cancelRequest",
                clearOldRequest : "CartModule/clearOldRequest"
            }
            return await new Promise((resolve,reject) => {
                const loading = state.loading;
                if (!loading) {
                    commit('loadingTrue')
                    let user = JSON.parse(localStorage.getItem('user'));     
                    netherlandsHttpService.post(`cart/${user.cartData.cartKey}/place-order`,payload, configParams).then((response) => {
                        commit('loadingFalse')
                        resolve(response.data)
                        dispatch('getCart')
                    }).catch((error) => {
                        commit('loadingFalse')
                        reject(error)
                    })
                }
            })
        },
        async addToCart({state,commit, dispatch}, payload) {

            const configParams = {
                req : "CartModule/req",
                loadingTrue : "CartModule/postLoadingTrue",
                addRequest : "CartModule/addRequest",
                cancelRequest : "CartModule/cancelRequest",
                clearOldRequest : "CartModule/clearOldRequest"
            }

            console.log("payload",payload)
            return await new Promise((resolve,reject) => {
                const loading = state.postLoading;
                if (!loading) {
                    commit('postLoadingTrue')

                    const cart = {
                        ...payload,
                        Quantity : 1,
                        Price : (payload.Price) ? payload.Price : 0,
                        U_imgURL : payload.U_imgURL.replace(/\/(large|small)-/, "/thumb-")
                    }
                    let user = JSON.parse(localStorage.getItem('user'));     
                    console.log("cart",user.cartData.cartKey)     
                    netherlandsHttpService.post(`cart/${user.cartData.cartKey}`,cart, configParams).then((response) => {

                        console.log("response",response)
                        commit('postLoadingFalse')
                        resolve(response.data)
                        dispatch('getCart')
                    }).catch((error) => {
                        commit('postLoadingFalse')
                        reject(error)
                    })
                }
            })
        },
        async updateCart({state,commit, dispatch}, payload) {

            const configParams = {
                req : "CartModule/req",
                loadingTrue : "CartModule/updateLoadingTrue",
                addRequest : "CartModule/addRequest",
                cancelRequest : "CartModule/cancelRequest",
                clearOldRequest : "CartModule/clearOldRequest"
            }
            return await new Promise((resolve,reject) => {
                const {updateLoading} = state;
                if (!updateLoading) {
                    commit('updateLoadingTrue')

                    const cart = {
                        ...payload,
                        Price : (payload.Price) ? payload.Price : 0,
                        U_imgURL : payload.U_imgURL.replace(/\/(large|small)-/, "/thumb-")
                    }

                    let user = JSON.parse(localStorage.getItem('user'));
                    netherlandsHttpService.update(`cart/${user.cartData.cartKey}`,cart,configParams).then((response) => {
                        commit('updateLoadingFalse')
                        resolve(response)
                        dispatch('getCart')
                    }).catch((error) => {
                        commit('updateLoadingFalse')
                        reject(error)
                    })
                }
            })
        },
        async deleteItem({state,commit, dispatch}, payload) {
            return await new Promise((resolve,reject) => {
                const loading = state.deleteLoading;
                if (!loading) {
                    commit('deleteLoadingTrue')
                    let user = JSON.parse(localStorage.getItem('user'));
                    
                    netherlandsHttpService.delete(`cart/${user.cartData.cartKey}`,payload).then((response) => {
                        commit('deleteLoadingFalse')
                        resolve(response.data)
                        console.log("Success",response)
                        dispatch('getCart')
                    }).catch((error) => {
                        commit('deleteLoadingFalse')
                        reject(error)
                    })
                }
            })
        },

        async createCart({ state, commit }) {
            return await new Promise((resolve, reject) => {
                const loading = state.createCartLoading;
                if (!loading) {
                    commit('createCartLoadingTrue')
                    let user = JSON.parse(localStorage.getItem('user'));
                    console.log("create cart",user.cartData)

                    const configParams = {
                        req : "CartModule/req",
                        loadingTrue : "CartModule/createCartLoadingTrue",
                        addRequest : "CartModule/addRequest",
                        cancelRequest : "CartModule/cancelRequest",
                        clearOldRequest : "CartModule/clearOldRequest"
                    }

                    netherlandsHttpService.post('cart/create',{...user.cartData},configParams).then((response) => {
                        commit('createCartLoadingFalse')
                        user.cartData = response.data.data.cartData;
                        localStorage.setItem('user', JSON.stringify(user));
                        commit('setCart', [])
                        resolve(response.data)
                    }).catch((error) => {
                        commit('createCartLoadingFalse')
                        reject(error)
                    })
                }
            })
        },
        async removeItem({ state, commit,dispatch }, payload) {
            return await new Promise((resolve, reject) => {
                const loading = state.deleteLoading;
                if (!loading) {
                    commit('loadingTrue')
                    netherlandsHttpService.post('cart-remove-item',payload).then((response) => {
                        commit('loadingFalse')
                        dispatch('getCart')
                        resolve(response.data)
                    }).catch((error) => {
                        commit('loadingFalse')
                        reject(error)
                    })
                }
            })
        },
        async reduceByOne({ state, commit,dispatch }, payload) {
            return await new Promise((resolve, reject) => {
                const loading = state.loading;
                if (!loading) {
                    commit('loadingTrue')
                    netherlandsHttpService.post('cart-reduce-one',payload).then((response) => {
                        commit('loadingFalse')
                        dispatch('getCart')
                        resolve(response.data)
                    }).catch((error) => {
                        commit('loadingFalse')
                        reject(error)
                    })
                }
            })
        },

        async clear({ state, commit,dispatch }) {
            return await new Promise((resolve, reject) => {
                const loading = state.loading;
                if (!loading) {
                    commit('loadingTrue')
                    netherlandsHttpService.post('cart-clear',{}).then((response) => {
                        commit('loadingFalse')
                        dispatch('getCart')
                        resolve(response.data)
                    }).catch((error) => {
                        commit('loadingFalse')
                        reject(error)
                    })
                }
            })
        },



    },
    mutations: {
        loadingFalse: (state) => {
            state.loading = false
        },
        loadingTrue: (state) => {
            state.loading = true
        },
        setCart: (state, payload) => {
            state.cart = payload
        },
        setCartItemCount: (state, payload) => {
            state.count = payload
        },
        setTotal: (state, payload) => {
            state.total = payload
        },

        addRequest : (state, req) => {
            state.request = {cancel : req.cancel, msg : "loading"}

        },
        cancelRequest : (state) => {

            if(state.request != null){
                state.request.cancel()
            }
            state.loading = true
        },
        clearOldRequest(state, message) {
            state.request.msg = message;
            state.request = null;
        },

        setPostLoading : (state, payload) => {
            state.postLoading = payload
        },
        postLoadingTrue : (state) => {
            state.postLoading = true
        },
        postLoadingFalse : (state) => {
            state.postLoading = false
        },
        getLoadingTrue : (state) => {
            state.getLoading = true
        },
        getLoadingFalse : (state) => {
            state.getLoading = false
        },
        updateLoadingTrue : (state) => {
            state.updateLoading = true
        },
        updateLoadingFalse : (state) => {
            state.updateLoading = false
        },
        deleteLoadingTrue : (state) => {
            state.deleteLoading = true
        },
        deleteLoadingFalse : (state) => {
            state.deleteLoading = false
        },
        createCartLoadingTrue : (state) => {
            state.createCartLoading = true
        },
        createCartLoadingFalse : (state) => {
            state.createCartLoading = false
        },

    },
    getters: {
        req: state => state.request,
        cart: state => state.cart,
        loading: state => state.loading,
        count: state => state.count,
        total: state => state.total,
        postLoading: state => state.postLoading,
        getLoading: state => state.getLoading,
        updateLoading: state => state.updateLoading,
        deleteLoading: state => state.deleteLoading,
        createCartLoading: state => state.createCartLoading
    }
}
