export const AppNetherlandsModule = {
    namespaced : true,
    state: {
        show_modal : false,
        layout : null,
        action : [],
    },
    mutations : {
        setModal(state,payload){
            state.show_modal = payload
        },
        setLayout(state,payload){
            state.layout = payload
        },
        setAction(state,payload){
            state.action = payload
        }
    },
    getters : {
        layout : state => state.layout,
        action : state => state.action,
        modal : state => state.show_modal,
    }
}