<template>
<div class="w-100">
    <mdbContainer>
        <div class="d-flex flex-fill align-items-center mt-4 customerHeaderContainer">
            <div class="customerBodyItem pt-2 pb-2">
               <strong>CUSTOMER DETAILS</strong>
            </div>
        </div>
        <ValidationObserver class="needs-validation form-custom-format" tag="form" ref="customerObserver"  v-slot="{passes}" @submit.prevent="onValidateCustomerForm()">    
            <!-- <validation-provider rules="required" vid="customerMobile" name="customerMobile" v-slot="{errors,invalid}">
                     <mobile-autocomplete
                                @search="requestCustomerNumber"
                                :data="customers"
                                :filterFunction="item => item"
                                label="Contact Number"
                                :isAsync="true" outline
                                @select="onSelectCustomer"
                                v-model="checkoutForm.Cellular"
                                @clear="onClear"
                                :invalidFeedback="errors[0]"
                                :is-invalid="invalid"
                                :inputClass="hasError(errors)" 
                            />
                            <template v-if="errors.length > 0">
                                <span class="validation-provider invalid-feedback">{{errors[0]}}</span>
                            </template>
                        </validation-provider>  -->


            <TextInput tabindex="2" labelClass="col-sm-3" fieldClass="col-sm-9 pl-0" label="Contact Number" placeholder="Contact Number" vid="customerMobileNumber" name="customerMobileNumber" v-model="checkoutForm.Cellular" size="sm"  :rules="''" />
            <TextInput tabindex="2" labelClass="col-sm-3" fieldClass="col-sm-9 pl-0" label="Customer Name" placeholder="Customer Name" vid="customername" name="customername" v-model="checkoutForm.CardName" size="sm"  :rules="'required'" />
            <TextInput tabindex="2" labelClass="col-sm-3" fieldClass="col-sm-9 pl-0" label="Email Address" placeholder="Email Address" vid="EmailAddress" name="EmailAddress" v-model="checkoutForm.EmailAddress" size="sm"  :rules="''" />
            
            <div class="d-flex flex-fill align-items-center mt-4 customerHeaderContainer">
                <div class="customerBodyItem pt-2 pb-2">
                <strong>ADDRESS</strong>
                </div>
            </div>
            
            <mdb-row>
                <mdb-col lg="6" md="6" sm="12">

                    <validation-provider rules="required" vid="selectCountry" name="selectCountry" v-slot="{errors,invalid}">
                            <country-autocomplete @select="onSelectCountry" :data="country" label="Country" :invalidFeedback="errors[0]" :is-invalid="invalid" :inputClass="hasError(errors)"  v-model="checkoutForm.MailCountry" outline :rules="'required'"/>
                            <template v-if="errors.length > 0">
                                <span class="validation-provider invalid-feedback">{{errors[0]}}</span>
                            </template>
                        </validation-provider>

                </mdb-col>
                
                <mdb-col lg="6" md="6" sm="12">
                    <TextInput tabindex="2" labelClass="col-sm-3" fieldClass="col-sm-9 pl-0" label="Zip Code" placeholder="Zip Code" vid="MailZipCode" name="MailZipCode" v-model="checkoutForm.MailZipCode" size="sm"  :rules="''"/>
                </mdb-col>
            </mdb-row>

            <mdb-row>
                <mdb-col lg="12" md="12" sm="12">
                    <TextInput tabindex="2" labelClass="col-sm-3" fieldClass="col-sm-9 pl-0" label="City" placeholder="City" vid="MailCity" name="MailCity" v-model="checkoutForm.MailCity" size="sm"  :rules="''"/>
                </mdb-col>
            </mdb-row>
            
            <mdb-row>
                <mdb-col lg="6" md="6" sm="12">
                    <TextInput tabindex="2" labelClass="col-sm-3" fieldClass="col-sm-9 pl-0" label="Street" placeholder="Street" vid="MailAddress" name="MailAddress" v-model="checkoutForm.MailAddress" size="sm"  :rules="''"/>
                </mdb-col>
                
                <mdb-col lg="6" md="6" sm="12">
                    <TextInput tabindex="2" labelClass="col-sm-3" fieldClass="col-sm-9 pl-0" label="Block" placeholder="Block" vid="MailBlock" name="MailBlock" v-model="checkoutForm.MailBlock" size="sm"  :rules="''"/>
                </mdb-col>
            </mdb-row>


            <el-collapse v-model="activePaneNum">
                <el-collapse-item title="ORDER SUMMARY" name="1">
                    <div class="item-detail-crt w-100">
                                        <dl class="row mx-0 mb-0">
                                            <dt class="col-sm-6 text-left item-total">Total</dt>
                                            <dd class="col-sm-6 text-right item-total-equal">{{cartTotal}}</dd>
                                        </dl>
                                    </div>
                </el-collapse-item>
            </el-collapse>


            <mdb-row>
                <mdb-col lg="12" md="12" sm="12">
                <template v-if="placeOrderLoading">
                    <mdb-btn color="elegant" size="sm" block class="mt-2 w-100 z-depth-0 w-100">
                    <span class="spinner-border spinner-border-sm " role="status" aria-hidden="true"></span>
                    Please wait...
                    </mdb-btn>
                </template>
                <template v-else>
                    <mdb-btn color="elegant" size="sm" block class="mt-2 w-100 z-depth-0 w-100" @click.native="passes(onValidateCustomerForm)">PLACE ORDER</mdb-btn>
                </template>
            </mdb-col>
            </mdb-row>
        </ValidationObserver>
        </mdbContainer>
        
</div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from 'vuex'
import {mdbContainer,mdbModalFooter,mdbCardFooter,mdbRow, mdbCol, mdbCard, mdbCardBody, mdbCardTitle,  mdbView, mdbCardImage,  mdbTooltip, mdbBtn, mdbModal, mdbModalBody, mdbModalHeader, mdbModalTitle, mdbAutocomplete} from 'mdbvue'

import {TextInput} from '../components/common'
import mobileAutocomplete from '../components/common/Autocomplete'
import countryAutocomplete from '../components/common/CountryAutocomplete'

import { ValidationObserver,ValidationProvider } from "vee-validate";

export default {
    components : {
        mdbContainer,mdbModalFooter,ValidationObserver,ValidationProvider,TextInput,mobileAutocomplete,mdbRow, mdbCol, mdbCard, mdbCardBody, mdbCardFooter, mdbCardTitle,  mdbView, mdbCardImage,  mdbTooltip, mdbBtn, mdbModal, mdbModalBody, mdbModalHeader, mdbModalTitle,mdbAutocomplete,countryAutocomplete
    },
    data : () => ({
        checkoutForm :  {
            CardCode : "",
            Cellular : "",
            CardName : "",
            EmailAddress : "",
            MailAddress : "",
            MailBlock : "",
            MailCity : "",
            MailZipCode : "",
            MailCountry : "Netherlands",
        },
        customers : [],
        activePaneNum: "1",
        placeOrderLoading : false
    }), 
    mounted(){
        
    },
    methods : {
        ...mapActions('UserModule',[
            'authToken'
        ]),
        ...mapActions('CustomerModule',[
            'findCustomer'
        ]),
        ...mapActions('ProductModule',[
            'resetData','getProduct'
        ]),
        ...mapMutations('ProductModule',[
            'setPage','setSearchTerm','setFilters'
        ]),
        ...mapActions('CartModule',[
            'addToCart','getCart','updateCart','deleteItem','placeOrder','createCart'
        ]),

        onValidateCustomerForm(){
            const success = this.$refs.customerObserver.validate();
            if (!success) {
                return;
            }

            console.log("this.checkoutForm",this.checkoutForm)

            if(success){
                this.placeOrderLoading = true;
                const swalWithBootstrapButtons = this.$swal.mixin({
                    customClass: {
                        confirmButton: 'btn btn-success',
                        cancelButton: 'btn btn-danger'
                    },
                    buttonsStyling: false
                })

                const formData = JSON.parse(JSON.stringify(this.checkoutForm));
                

                const selectedCountry = this.country.filter((item) => {
                    return item.label === formData.MailCountry
                },[]);

                if(selectedCountry.length > 0){
                    formData.MailCountry = selectedCountry[0].value
                }

                this.placeOrder(formData).then((response) => {
                    this.placeOrderLoading = false;
                    console.log("placeOrder")
                    console.log(response)
                    this.createCart();
                    swalWithBootstrapButtons.fire(
                        'Success!',
                        `Your order has been placed. Ref. # ${response.data.DocNum}`,
                        'success'
                    ).then((result) => {
                        if (result.isConfirmed) {
                            this.$router.push({name : 'welcome'})
                        }
                    })
                    
                }).catch((err) => {
                    this.placeOrderLoading = false;
                    console.log(err)
                })
            }
        },
        requestCustomerNumber(value){
            const self = this
            if (value === "") {
                this.customers = [];
                return;
            }
            if (this.debounceCustomerTimer) {
                clearTimeout(this.debounceCustomerTimer); 
            }

            this.debounceCustomerTimer = setTimeout(() => {
            if (value === "") {
                this.customers = [];
                return;
            }
            this.findCustomer({ term: value })
                .then((res) => {
                    // console.log("findCustomer",res)
                    self.customers = res.data;

                    if(res.data.length === 0){
                        self.checkoutForm.Cellular = value
                    }
                })
                .catch((err) => {
                    console.log(err);
                    self.customers = [];
                });
            }, 300);
        },
        onSelectCustomer(v){
            const self = this;
            self.checkoutForm.CardCode = v.CardCode;
            self.checkoutForm.Cellular = v.Cellular;
            self.checkoutForm.CardName = v.CardName;
            self.checkoutForm.ShippingAddress = v.ShippingAddress;
        },
        hasError(error){
            return (error.length > 0) ? 'is-invalid' : null
        },

        onClear(){
            this.checkoutForm = this.customerObj();
        },

        onSelectCountry(value){
            console.log(value)
        }

    },
    computed : {
        ...mapGetters('CartModule',['cart']),
        cartTotal : function(){
            const total = this.cart.map((item) => {
                    return this.$utils.unformat(item.LineTotal)
                }).reduce((a, b) => a + b, 0);
            return this.$utils.formatMoney(total);
        },

        country : function(){
            return [ 
                {label: 'Afghanistan', value: 'AF'}, 
                {label: 'Åland Islands', value: 'AX'}, 
                {label: 'Albania', value: 'AL'}, 
                {label: 'Algeria', value: 'DZ'}, 
                {label: 'American Samoa', value: 'AS'}, 
                {label: 'AndorrA', value: 'AD'}, 
                {label: 'Angola', value: 'AO'}, 
                {label: 'Anguilla', value: 'AI'}, 
                {label: 'Antarctica', value: 'AQ'}, 
                {label: 'Antigua and Barbuda', value: 'AG'}, 
                {label: 'Argentina', value: 'AR'}, 
                {label: 'Armenia', value: 'AM'}, 
                {label: 'Aruba', value: 'AW'}, 
                {label: 'Australia', value: 'AU'}, 
                {label: 'Austria', value: 'AT'}, 
                {label: 'Azerbaijan', value: 'AZ'}, 
                {label: 'Bahamas', value: 'BS'}, 
                {label: 'Bahrain', value: 'BH'}, 
                {label: 'Bangladesh', value: 'BD'}, 
                {label: 'Barbados', value: 'BB'}, 
                {label: 'Belarus', value: 'BY'}, 
                {label: 'Belgium', value: 'BE'}, 
                {label: 'Belize', value: 'BZ'}, 
                {label: 'Benin', value: 'BJ'}, 
                {label: 'Bermuda', value: 'BM'}, 
                {label: 'Bhutan', value: 'BT'}, 
                {label: 'Bolivia', value: 'BO'}, 
                {label: 'Bosnia and Herzegovina', value: 'BA'}, 
                {label: 'Botswana', value: 'BW'}, 
                {label: 'Bouvet Island', value: 'BV'}, 
                {label: 'Brazil', value: 'BR'}, 
                {label: 'British Indian Ocean Territory', value: 'IO'}, 
                {label: 'Brunei Darussalam', value: 'BN'}, 
                {label: 'Bulgaria', value: 'BG'}, 
                {label: 'Burkina Faso', value: 'BF'}, 
                {label: 'Burundi', value: 'BI'}, 
                {label: 'Cambodia', value: 'KH'}, 
                {label: 'Cameroon', value: 'CM'}, 
                {label: 'Canada', value: 'CA'}, 
                {label: 'Cape Verde', value: 'CV'}, 
                {label: 'Cayman Islands', value: 'KY'}, 
                {label: 'Central African Republic', value: 'CF'}, 
                {label: 'Chad', value: 'TD'}, 
                {label: 'Chile', value: 'CL'}, 
                {label: 'China', value: 'CN'}, 
                {label: 'Christmas Island', value: 'CX'}, 
                {label: 'Cocos (Keeling) Islands', value: 'CC'}, 
                {label: 'Colombia', value: 'CO'}, 
                {label: 'Comoros', value: 'KM'}, 
                {label: 'Congo', value: 'CG'}, 
                {label: 'Congo, The Democratic Republic of the', value: 'CD'}, 
                {label: 'Cook Islands', value: 'CK'}, 
                {label: 'Costa Rica', value: 'CR'}, 
                {label: 'Cote D\'Ivoire', value: 'CI'}, 
                {label: 'Croatia', value: 'HR'}, 
                {label: 'Cuba', value: 'CU'}, 
                {label: 'Cyprus', value: 'CY'}, 
                {label: 'Czech Republic', value: 'CZ'}, 
                {label: 'Denmark', value: 'DK'}, 
                {label: 'Djibouti', value: 'DJ'}, 
                {label: 'Dominica', value: 'DM'}, 
                {label: 'Dominican Republic', value: 'DO'}, 
                {label: 'Ecuador', value: 'EC'}, 
                {label: 'Egypt', value: 'EG'}, 
                {label: 'El Salvador', value: 'SV'}, 
                {label: 'Equatorial Guinea', value: 'GQ'}, 
                {label: 'Eritrea', value: 'ER'}, 
                {label: 'Estonia', value: 'EE'}, 
                {label: 'Ethiopia', value: 'ET'}, 
                {label: 'Falkland Islands (Malvinas)', value: 'FK'}, 
                {label: 'Faroe Islands', value: 'FO'}, 
                {label: 'Fiji', value: 'FJ'}, 
                {label: 'Finland', value: 'FI'}, 
                {label: 'France', value: 'FR'}, 
                {label: 'French Guiana', value: 'GF'}, 
                {label: 'French Polynesia', value: 'PF'}, 
                {label: 'French Southern Territories', value: 'TF'}, 
                {label: 'Gabon', value: 'GA'}, 
                {label: 'Gambia', value: 'GM'}, 
                {label: 'Georgia', value: 'GE'}, 
                {label: 'Germany', value: 'DE'}, 
                {label: 'Ghana', value: 'GH'}, 
                {label: 'Gibraltar', value: 'GI'}, 
                {label: 'Greece', value: 'GR'}, 
                {label: 'Greenland', value: 'GL'}, 
                {label: 'Grenada', value: 'GD'}, 
                {label: 'Guadeloupe', value: 'GP'}, 
                {label: 'Guam', value: 'GU'}, 
                {label: 'Guatemala', value: 'GT'}, 
                {label: 'Guernsey', value: 'GG'}, 
                {label: 'Guinea', value: 'GN'}, 
                {label: 'Guinea-Bissau', value: 'GW'}, 
                {label: 'Guyana', value: 'GY'}, 
                {label: 'Haiti', value: 'HT'}, 
                {label: 'Heard Island and Mcdonald Islands', value: 'HM'}, 
                {label: 'Holy See (Vatican City State)', value: 'VA'}, 
                {label: 'Honduras', value: 'HN'}, 
                {label: 'Hong Kong', value: 'HK'}, 
                {label: 'Hungary', value: 'HU'}, 
                {label: 'Iceland', value: 'IS'}, 
                {label: 'India', value: 'IN'}, 
                {label: 'Indonesia', value: 'ID'}, 
                {label: 'Iran, Islamic Republic Of', value: 'IR'}, 
                {label: 'Iraq', value: 'IQ'}, 
                {label: 'Ireland', value: 'IE'}, 
                {label: 'Isle of Man', value: 'IM'}, 
                {label: 'Israel', value: 'IL'}, 
                {label: 'Italy', value: 'IT'}, 
                {label: 'Jamaica', value: 'JM'}, 
                {label: 'Japan', value: 'JP'}, 
                {label: 'Jersey', value: 'JE'}, 
                {label: 'Jordan', value: 'JO'}, 
                {label: 'Kazakhstan', value: 'KZ'}, 
                {label: 'Kenya', value: 'KE'}, 
                {label: 'Kiribati', value: 'KI'}, 
                {label: 'Korea, Democratic People\'S Republic of', value: 'KP'}, 
                {label: 'Korea, Republic of', value: 'KR'}, 
                {label: 'Kuwait', value: 'KW'}, 
                {label: 'Kyrgyzstan', value: 'KG'}, 
                {label: 'Lao People\'S Democratic Republic', value: 'LA'}, 
                {label: 'Latvia', value: 'LV'}, 
                {label: 'Lebanon', value: 'LB'}, 
                {label: 'Lesotho', value: 'LS'}, 
                {label: 'Liberia', value: 'LR'}, 
                {label: 'Libyan Arab Jamahiriya', value: 'LY'}, 
                {label: 'Liechtenstein', value: 'LI'}, 
                {label: 'Lithuania', value: 'LT'}, 
                {label: 'Luxembourg', value: 'LU'}, 
                {label: 'Macao', value: 'MO'}, 
                {label: 'Macedonia, The Former Yugoslav Republic of', value: 'MK'}, 
                {label: 'Madagascar', value: 'MG'}, 
                {label: 'Malawi', value: 'MW'}, 
                {label: 'Malaysia', value: 'MY'}, 
                {label: 'Maldives', value: 'MV'}, 
                {label: 'Mali', value: 'ML'}, 
                {label: 'Malta', value: 'MT'}, 
                {label: 'Marshall Islands', value: 'MH'}, 
                {label: 'Martinique', value: 'MQ'}, 
                {label: 'Mauritania', value: 'MR'}, 
                {label: 'Mauritius', value: 'MU'}, 
                {label: 'Mayotte', value: 'YT'}, 
                {label: 'Mexico', value: 'MX'}, 
                {label: 'Micronesia, Federated States of', value: 'FM'}, 
                {label: 'Moldova, Republic of', value: 'MD'}, 
                {label: 'Monaco', value: 'MC'}, 
                {label: 'Mongolia', value: 'MN'}, 
                {label: 'Montserrat', value: 'MS'}, 
                {label: 'Morocco', value: 'MA'}, 
                {label: 'Mozambique', value: 'MZ'}, 
                {label: 'Myanmar', value: 'MM'}, 
                {label: 'Namibia', value: 'NA'}, 
                {label: 'Nauru', value: 'NR'}, 
                {label: 'Nepal', value: 'NP'}, 
                {label: 'Netherlands', value: 'NL'}, 
                {label: 'Netherlands Antilles', value: 'AN'}, 
                {label: 'New Caledonia', value: 'NC'}, 
                {label: 'New Zealand', value: 'NZ'}, 
                {label: 'Nicaragua', value: 'NI'}, 
                {label: 'Niger', value: 'NE'}, 
                {label: 'Nigeria', value: 'NG'}, 
                {label: 'Niue', value: 'NU'}, 
                {label: 'Norfolk Island', value: 'NF'}, 
                {label: 'Northern Mariana Islands', value: 'MP'}, 
                {label: 'Norway', value: 'NO'}, 
                {label: 'Oman', value: 'OM'}, 
                {label: 'Pakistan', value: 'PK'}, 
                {label: 'Palau', value: 'PW'}, 
                {label: 'Palestinian Territory, Occupied', value: 'PS'}, 
                {label: 'Panama', value: 'PA'}, 
                {label: 'Papua New Guinea', value: 'PG'}, 
                {label: 'Paraguay', value: 'PY'}, 
                {label: 'Peru', value: 'PE'}, 
                {label: 'Philippines', value: 'PH'}, 
                {label: 'Pitcairn', value: 'PN'}, 
                {label: 'Poland', value: 'PL'}, 
                {label: 'Portugal', value: 'PT'}, 
                {label: 'Puerto Rico', value: 'PR'}, 
                {label: 'Qatar', value: 'QA'}, 
                {label: 'Reunion', value: 'RE'}, 
                {label: 'Romania', value: 'RO'}, 
                {label: 'Russian Federation', value: 'RU'}, 
                {label: 'RWANDA', value: 'RW'}, 
                {label: 'Saint Helena', value: 'SH'}, 
                {label: 'Saint Kitts and Nevis', value: 'KN'}, 
                {label: 'Saint Lucia', value: 'LC'}, 
                {label: 'Saint Pierre and Miquelon', value: 'PM'}, 
                {label: 'Saint Vincent and the Grenadines', value: 'VC'}, 
                {label: 'Samoa', value: 'WS'}, 
                {label: 'San Marino', value: 'SM'}, 
                {label: 'Sao Tome and Principe', value: 'ST'}, 
                {label: 'Saudi Arabia', value: 'SA'}, 
                {label: 'Senegal', value: 'SN'}, 
                {label: 'Serbia and Montenegro', value: 'CS'}, 
                {label: 'Seychelles', value: 'SC'}, 
                {label: 'Sierra Leone', value: 'SL'}, 
                {label: 'Singapore', value: 'SG'}, 
                {label: 'Slovakia', value: 'SK'}, 
                {label: 'Slovenia', value: 'SI'}, 
                {label: 'Solomon Islands', value: 'SB'}, 
                {label: 'Somalia', value: 'SO'}, 
                {label: 'South Africa', value: 'ZA'}, 
                {label: 'South Georgia and the South Sandwich Islands', value: 'GS'}, 
                {label: 'Spain', value: 'ES'}, 
                {label: 'Sri Lanka', value: 'LK'}, 
                {label: 'Sudan', value: 'SD'}, 
                {label: 'Surilabel', value: 'SR'}, 
                {label: 'Svalbard and Jan Mayen', value: 'SJ'}, 
                {label: 'Swaziland', value: 'SZ'}, 
                {label: 'Sweden', value: 'SE'}, 
                {label: 'Switzerland', value: 'CH'}, 
                {label: 'Syrian Arab Republic', value: 'SY'}, 
                {label: 'Taiwan, Province of China', value: 'TW'}, 
                {label: 'Tajikistan', value: 'TJ'}, 
                {label: 'Tanzania, United Republic of', value: 'TZ'}, 
                {label: 'Thailand', value: 'TH'}, 
                {label: 'Timor-Leste', value: 'TL'}, 
                {label: 'Togo', value: 'TG'}, 
                {label: 'Tokelau', value: 'TK'}, 
                {label: 'Tonga', value: 'TO'}, 
                {label: 'Trinidad and Tobago', value: 'TT'}, 
                {label: 'Tunisia', value: 'TN'}, 
                {label: 'Turkey', value: 'TR'}, 
                {label: 'Turkmenistan', value: 'TM'}, 
                {label: 'Turks and Caicos Islands', value: 'TC'}, 
                {label: 'Tuvalu', value: 'TV'}, 
                {label: 'Uganda', value: 'UG'}, 
                {label: 'Ukraine', value: 'UA'}, 
                {label: 'United Arab Emirates', value: 'AE'}, 
                {label: 'United Kingdom', value: 'GB'}, 
                {label: 'United States', value: 'US'}, 
                {label: 'United States Minor Outlying Islands', value: 'UM'}, 
                {label: 'Uruguay', value: 'UY'}, 
                {label: 'Uzbekistan', value: 'UZ'}, 
                {label: 'Vanuatu', value: 'VU'}, 
                {label: 'Venezuela', value: 'VE'}, 
                {label: 'Viet Nam', value: 'VN'}, 
                {label: 'Virgin Islands, British', value: 'VG'}, 
                {label: 'Virgin Islands, U.S.', value: 'VI'}, 
                {label: 'Wallis and Futuna', value: 'WF'}, 
                {label: 'Western Sahara', value: 'EH'}, 
                {label: 'Yemen', value: 'YE'}, 
                {label: 'Zambia', value: 'ZM'}, 
                {label: 'Zimbabwe', value: 'ZW'} 
                ]
        }
    }
}

</script>

<style scoped lang="scss">
.customerHeaderContainer{
    border-bottom: 1px solid #e0e0e0;
}
.customerBodyItem{
    flex: 1 1 calc(50% - 10px); 
      box-sizing: border-box; 
      .btn{
        padding-left: 10px;
        padding-right: 10px
      }
}

</style>