<template>
<div class="w-100">
    <template v-if="searchTerm != null && searchTerm != ''">
        <div class="d-flex flex-fill align-items-center mt-5">
            <mdb-alert color="success" class="w-100 mb-0">

                <div class="d-flex justify-content-between align-items-center w-100">
                    <span>Search result for : <strong>{{searchTerm}}</strong></span>
                    <mdb-btn size="sm" color="info" class="z-depth-0 mx-0 round rounded" @click="onClearSearch">Clear</mdb-btn>
                </div>
            </mdb-alert>
        </div>
    </template>

  
    <template v-if="products.length > 0">
        <div class="flex-item-container">
            <mdb-card 
                cascade 
                narrow 
                ecommerce 
                class="card border border-light z-depth-0 flex-item-card" 
                :key="item.id" 
                v-for="item in products"
            >
                <a href="javascript:void(0)" @click="viewGallery(item)" class="flex-itemsx">
                <mdb-view overlay="white-slight">
                    <mdb-card-image :src="item.U_imgURL" />
                </mdb-view>
                </a>
                <mdb-input type="checkbox" :id="'xItem'+item.ItemCode" :name="'xItem'+item.ItemCode" v-model="item.selected" label=" " class="xItemCheckbox" filled @change="onCheck($event,item)"/>
                <mdb-card-body class="card-body flex-body text-center p-1">
                <a href="" class="text-dark">
                    <h6 class="product-name"><strong>{{ item.ItemName }}</strong></h6>
                </a>
                <p class="text-small product-other-details mb-2">
                    {{ item.ItemCode }} <br />
                </p>

                <div class="card-footer flex-footer-card px-2">
                    <template v-if="isUserLoggedIn">
                        <div class="d-flex align-items-center justify-content-between py-1">
                        <span class="float-leftx font-weight-bold">
                            <strong>Price</strong>
                        </span>
                        <span class="float-rightx">
                            <strong>{{ $utils.formatMoney(item.Price) }} {{ item.Currency }}</strong>
                        </span>
                        </div>
                    </template>
                    <mdb-btn
                    size="sm"
                    color="elegant"
                    class="z-depth-0 w-100 mx-0"
                    @click="viewProduct(item.ItemCode)"
                    >
                    View
                    </mdb-btn>
                </div>
                </mdb-card-body>
            </mdb-card>
            </div>
    </template>

    <template v-else>
        <div class="d-flex flex-fill align-items-center mt-5">
            <mdb-alert color="warning" class="w-100">
            <div class="d-flex justify-content-between align-items-center w-100">
                <span>No Data Available</span>
            </div>
            </mdb-alert>
        </div>
    </template>

    <template v-if="products.length > 0 && page <= last_page">
        <div class="d-flex flex-fill align-items-center mt-3">
            <template v-if="total > length">
                <mdb-btn size="sm" color="elegant" class="z-depth-0 w-100 mx-1" @click="prevPage">Previous</mdb-btn>
            </template> 
            <template v-if="page < last_page">
                <mdb-btn size="sm" color="elegant" class="z-depth-0 w-100 mx-1" @click="nextPage">Next</mdb-btn>
            </template>
        </div>
    </template>


    <CoolLightBox :items="productImages" :index="productImageIndex" :useZoomBar="true" @close="productImageIndex = null"></CoolLightBox>



      <mdb-modal :show="modalLoading" size="lg" @close="modalLoading = false" centered class="z-depth-0">
           
            <mdb-modal-body>

            <strong><span class="spinner-border spinner-border-sm " role="status" aria-hidden="true"></span>
                              Please wait...</strong>
          </mdb-modal-body>

      </mdb-modal>


      <mdb-modal :show="modalSearch" @close="modalSearch = false" class="z-depth-0 modal-filter" full-height position="left">
           
            <mdb-modal-body>
            <TextInput tabindex="2" labelClass="col-sm-3" fieldClass="col-sm-9 pl-0" label="Search" placeholder="Search" vid="Search" name="Search" v-model="searchTermInput" size="sm"  :rules="''"/>

            <mdb-list-group>
                    <a href="javascript:void(0)"  v-for="item in categories" :key="item.CategoryCode" @click="onFilter(item.CategoryCode)" class="list-group-item d-flex justify-content-between align-items-center text-dark">{{ item.CategoryName }}<mdb-badge class="z-depth-0 categor-filter-badge">{{ item.Count }}</mdb-badge></a>
            </mdb-list-group>
          </mdb-modal-body>
          <mdb-modal-footer>
            <mdb-btn color="black" size="sm" class="mt-2 w-100 z-depth-0" @click="onSearchTerm">Search</mdb-btn>
            <mdb-btn color="black" size="sm" class="mt-1 w-100 z-depth-0" @click="onClearSearch">Clear</mdb-btn>
          </mdb-modal-footer>
      </mdb-modal>

      
</div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from 'vuex'
import {mdbAlert,mdbModalFooter, mdbCard, mdbCardBody,  mdbView, mdbCardImage,  mdbBtn, mdbModal, mdbModalBody, mdbModalHeader, mdbModalTitle, mdbInput, mdbListGroup, mdbListGroupItem, mdbBadge} from 'mdbvue'
import unformat from 'accounting-js/lib/unformat.js'
import formatMoney from 'accounting-js/lib/formatMoney.js'
import {TextInput} from '../components/common'
import { getUserData } from '../helpers';
import CoolLightBox from '../components/CoolLightBox.vue'
export default {
    components : {
        CoolLightBox,mdbAlert,mdbModalFooter,TextInput, mdbCard, mdbCardBody,  mdbView, mdbCardImage,  mdbBtn, mdbModal, mdbModalBody, mdbModalHeader, mdbModalTitle, mdbInput,
        mdbListGroup, mdbListGroupItem, mdbBadge
    },
    data : () => ({
        decodedString : null,
        decodedLoginString : null,
        checking : false,
        scanModal : false,
        modal : false,
        records: [],
        items: [],
        index: [],
        loading: false,
        product : null,
        productLoading : true,
        modalCart : false,
        modalScan : false,
        modalCustomer : false,
        customers : [],
        checkoutForm : null,
        placeOrderLoading : false,
        notfound : false,
        sales_person : "",
        perPage : 24,
        totalRecord : 24,
        current_page : 1,
        modalLoading : false,
        modalSearch : false,
        clearSearch : false,
        searchTermInput : null,
        searchTermTrue : null,
        constraints: {
            facingMode: 'user'
        },

        modalKey : 0,
        debounceCustomerTimer : null,
        processFormLoading : false,
        isUserLoggedIn : false,
        productImages : [],
        productImageIndex : null
    }), 
    mounted(){
        const self = this;
        this.checkoutForm = this.customerObj();

        if(this.products.length === 0){
            // this.fnSearchProduct();
        }

      /*   this.$eventBus.$on('open-cart', function(){
            self.modalCart = true
        }) */
        
        /* this.$eventBus.$on('open-scan', function(){
            self.modalScan = true
            self.decodedString = null
            self.notfound = false
            self.checking = false
        }) */
        
        this.$eventBus.$on('open-filter', function(){
            self.modalSearch = true
        })
        const user = getUserData();

        // eslint-disable-next-line no-prototype-builtins
        if(user !== null && user.hasOwnProperty('user') && user.user !== null){
            console.log("user",user)
            this.sales_person = user.user.name
            this.isUserLoggedIn = true
        }

       /*  if(user.user !== null){
            this.sales_person = user.user.name
        } */
        if(this.categories.length === 0){
            this.getCategory()
        }
        
    },
    methods : {
        
        ...mapActions('UserModule',[
            'authToken'
        ]),
        ...mapActions('CustomerModule',[
            'findCustomer'
        ]),
        ...mapActions('ProductModule',[
            'resetData','getProduct'
        ]),
        ...mapActions('CategoryModule',{
            getCategory : 'resetData'
        }),
        ...mapMutations('ProductModule',[
            'setPage','setSearchTerm','setFilters','setPreviousQuery'
        ]),
        ...mapActions('CartModule',[
            'addToCart','getCart','updateCart','deleteItem','placeOrder','createCart'
        ]),
        isValid(isInvalid,inpt){
            return ((isInvalid === true && inpt === '') || (inpt === '' && this.validated === true)) ? 'is-invalid' : null
        },
        hasError(error){
            return (error.length > 0) ? 'is-invalid' : null
        },
        async fnSearchProduct(){
            const self = this
            self.loading = true;
            self.modalLoading = true;
            await this.resetData().then(() => {
                self.modalLoading = false
                 self.scrollToTop()
                
            }).catch((err) => {
                console.log("View Product error",err)
                 self.modalLoading = false
            })
        },

        viewGallery(product){
            if(product.Images.length > 0){
                this.productImages = product.Images.map((i) => {
                        return {
                            src : i,
                            description : product.Dimension,
                            title : product.ItemName
                        }
                    });

                this.productImageIndex = 0
            }
        },

        fnAddToCart(){
            const self = this;
            console.log("fn add to cart",this.product)
            this.addToCart(this.product).then(() => {
                self.$ThatsNotify.success({
                    message: "Item was succesfully added to list"
                });
            });
        },

        viewProduct(series){
            // const self = this
            /* this.modalKey++
            this.modalLoading = true
            self.productLoading = true */
            // this.$router.push({name : 'product',params : {Itemcode : series}})
            this.$router.push({ name: 'product', query: { item: series } });
           /*  this.getProduct(series).then((response) => {
                console.log("response viewProduct",response)
                self.product = {
                    ...response,
                    U_imgURL : response.U_imgURL.replace(/\/(small|thumb)-/, "/large-")
                }
                self.productLoading = false
                self.modalScan = false
                self.modal = true
                self.modalLoading = false
                // self.getDefault()
            }).catch((err) => {

                console.log("View Product error",err)
                 self.modal = false
                self.productLoading = false
                self.checking = false
                self.notfound = true
                self.modalLoading = false
            }) */
        },

        incrementItem(v){
            const Quantity = v.Quantity + 1;
            v.Quantity = Quantity;
            var UnitPrice = this.$utils.unformat(v.UnitPrice);
            v.LineTotal = parseFloat(UnitPrice) * parseInt(Quantity);
            console.log("incrementItem",v)
            this.updateCart(v)
      },
      decrementItem(index,v){

         
        if(v.Quantity >= 2){

            const Quantity = v.Quantity - 1;
            v.Quantity = Quantity;

            var UnitPrice = this.$utils.unformat(v.UnitPrice);
            v.LineTotal = parseFloat(UnitPrice) * parseInt(Quantity);
            console.log("decrementItem",v)
            this.updateCart(v)
        }
      },

      fnRemoveItem(index,item){

            const self = this

            const swalWithBootstrapButtons = this.$swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-success z-depth-0',
                    cancelButton: 'btn btn-danger z-depth-0'
                },
                buttonsStyling: false
                })

                swalWithBootstrapButtons.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, delete it!',
                cancelButtonText: 'No, cancel!',
                reverseButtons: true
                }).then((result) => {
                if (result.isConfirmed) {
                    console.log("deleteItem",item)
                    self.deleteItem(item).then((res) => {

                        console.log("delete response",res)
                        swalWithBootstrapButtons.fire(
                            'Deleted!',
                            'Item has been deleted.',
                            'success'
                            )
                            self.cart.splice(index,1)
                    }).catch(() => {
                        swalWithBootstrapButtons.fire(
                        'Cancelled',
                        'Something went wrong',
                        'error'
                        )
                    })  
                    
                } else{
                    swalWithBootstrapButtons.fire(
                    'Cancelled',
                    'Action cancelled',
                    'error'
                    )
                }
                })

      },

      checkoutCart(){
        this.modalCart = false
        this.modalCustomer = true
      },

      customerObj(){
          return {
                contact_id : "",
                mobile : "",
                name : "",
                address : "",
                instructions : "",
            }
        },

      requestCustomerNumber(value){
            const self = this
            if (value === "") {
                this.customers = [];
                return;
            }
            if (this.debounceCustomerTimer) {
                clearTimeout(this.debounceCustomerTimer); 
            }

            this.debounceCustomerTimer = setTimeout(() => {
            if (value === "") {
                this.customers = [];
                return;
            }
            this.findCustomer({ term: value })
                .then((res) => {
                    console.log("findCustomer",res)
                    self.customers = res.data;
                })
                .catch((err) => {
                    console.log(err);
                    self.customers = [];
                });
            }, 300);
        },
        onSelectCustomer(v){
            const self = this;
            self.checkoutForm.CardCode = v.CardCode;
            self.checkoutForm.Cellular = v.Cellular;
            self.checkoutForm.CardName = v.CardName;
            self.checkoutForm.ShippingAddress = v.ShippingAddress;
        },
        onClear(){
            this.checkoutForm = this.customerObj();
        },
        onProcessForm(){
            const success = this.$refs.customeObserver.validate();
            if (!success) {
                return;
            }

            this.processFormLoading = true;

        },
        onPlaceOrderForm(){
          const self = this;
          self.placeOrderLoading = true;
          const success = this.$refs.customeObserver.validate();
            if (!success) {
                return;
            }

            const swalWithBootstrapButtons = this.$swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-danger'
                },
                buttonsStyling: false
                })

            if (success) {
                
                // console.log(this.checkoutForm)
                this.placeOrder(this.checkoutForm).then((response) => {
                    
                    console.log("placeOrder")
                    console.log(response)
                    swalWithBootstrapButtons.fire(
                        'Success!',
                        `Your order has been placed. Ref. # ${response.data.series}`,
                        'success'
                    )
                    self.checkoutForm = self.customerObj();
                    self.placeOrderLoading = false;
                    self.modalCustomer = false;
                });

            }
      },

        onDecode(decodedString){
            this.decodedString = decodedString;
            this.checking = true;
            this.viewProduct(decodedString)
        },

        repaint (location, ctx) {
            const {
            topLeftCorner,
            topRightCorner,
            bottomLeftCorner,
            bottomRightCorner,
            } = location

            ctx.strokeStyle = 'blue' // instead of red

            ctx.beginPath()
            ctx.moveTo(topLeftCorner.x, topLeftCorner.y)
            ctx.lineTo(bottomLeftCorner.x, bottomLeftCorner.y)
            ctx.lineTo(bottomRightCorner.x, bottomRightCorner.y)
            ctx.lineTo(topRightCorner.x, topRightCorner.y)
            ctx.lineTo(topLeftCorner.x, topLeftCorner.y)
            ctx.closePath()

            ctx.stroke()
        },

        onDecodeLogin(decodedString){
            const self = this
            this.decodedLoginString = decodedString;
             const splitQcode = this.decodedLoginString.split('-x-');
            if(splitQcode.length > 1){
                       /*  const locationToken = splitQcode[1].replace(/(\r\n|\n|\r)/gm, "");

                   
                            const params = {token : locationToken, bearer : splitQcode[0]}; */
                            this.authToken(splitQcode[0]).then((res) => {
                                console.log(res)
                                if (res.data.token) {
                                    localStorage.setItem('user', JSON.stringify(res.data));
                                }
                                self.$router.go()
                                // self.$router.push({ name: 'welcome' });
                            }).catch(error => {
                                
                            })
                    }
        },

        nextPage(){
            const nextPage = (this.page == this.last_page) ? this.page : this.page + 1;
            const currentQuery = this.$route.query;
            this.$router.push({ name: 'welcome', query: { ...currentQuery, page: nextPage } });
        },

        prevPage(){
            const prevPage = (this.page == 1) ? this.page : this.page - 1;
            const currentQuery = this.$route.query;

            
            this.$router.push({ name: 'welcome', query: { ...currentQuery, page: prevPage } });
        },

        onSearchTerm(){
           
            if(this.searchTermInput != null && this.searchTermInput != ""){
                
               /*  this.setPage(1)
                this.setSearchTerm(this.searchTerm)
                const filter = {"searchTerm":this.searchTerm,"category" : null}
                this.setFilters(filter) */
                this.modalSearch = false;
                // this.fnSearchProduct()
                const currentQuery = this.$route.query;
                this.$router.push({ name: 'welcome', query: { ...currentQuery, page: 1, category: null, term: this.searchTermInput } });
            }
        },

        onFilter(category){
            this.setPage(1)
            const filter = {"term":this.searchTerm,"category" : category}
            this.setFilters(filter)
            // this.fnSearchProduct()
            this.modalSearch = false;
            this.$router.push({ name: 'welcome', query: { category: category, page: 1, term : null } });
        },

        onClearSearch(){
            /* this.clearSearch = true
            this.setPage(1)
            this.setSearchTerm(null)
            const filter = {"searchTerm":null,"category" : null}
            this.setFilters(filter)
            this.modalSearch = false;
            this.fnSearchProduct() */
            this.setSearchTerm(null)
            const currentQuery = this.$route.query;
            this.modalSearch = false;
            this.$router.push({ name: 'welcome', query: { ...currentQuery, page: 1, category: null, term : null } });
        },
        scrollToTop() {
            window.scrollTo({
                top: 0,
                behavior: 'smooth', 
            });
        },

        fnFormatMoney(v){
            return formatMoney(v,{ symbol: "", precision: 2, thousand: ",", decimal: "." });
        },

        clearCart(){

            const self = this

            const swalClearCart = this.$swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-success z-depth-0',
                    cancelButton: 'btn btn-danger z-depth-0'
                },
                buttonsStyling: false
                });

                swalClearCart.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, clear it!',
                cancelButtonText: 'No, cancel!',
                reverseButtons: true
                }).then((result) => {
                if (result.isConfirmed) {
                    
                    self.createCart().then((res) => {

                        console.log("create response",res)
                        swalClearCart.fire(
                            'Created!',
                            'Cart has been created.',
                            'success'
                            )
                    }).catch(() => {
                        swalClearCart.fire(
                        'Cancelled',
                        'Something went wrong',
                        'error'
                        )
                    })  
                    
                } else{
                    swalClearCart.fire(
                    'Cancelled',
                    'Action cancelled.',
                    'error'
                    )
                }
                })
        },

        onCheck(e,item){
            const self = this
            const selectedItem = {...item,selected:e}
            if(e){
                
                const cartItems = this.cart.filter((v) => v.ItemCode == item.ItemCode,[])
                if(cartItems.length == 0){
                    this.addToCart(selectedItem)
                }
                // this.addToCart(selectedItem)


                /* .then(() => {
                    self.$ThatsNotify.success({
                        message: "Item was succesfully added to cart"
                    });
                }); */
            }else{
                self.deleteItem(selectedItem)
            }
        },

        onClickCheckbox(e,item){
            const self = this
            alert(e)
        },

        handleModalHide(event){
            console.log(event)
        }
    },

    watch : {
        products : {
            handler : function(newVal){
                this.records = newVal
            },
            deep : true
        },
        /* '$route.query.page': {
            immediate: true,
            handler : function(newVal){
                // this.viewProduct(newVal)
                console.log("page",newVal)
            }
        } */
       '$route.query': {
            handler(newQuery, oldQuery) {
                console.log("previousQuery:", this.getPreviousQuery);
                console.log("Old Query:", oldQuery);
                console.log("New Query:", newQuery);


                //When in the same page and change the query
                if (JSON.stringify(newQuery) !== JSON.stringify(oldQuery) && oldQuery != null) {
                    const p = newQuery.page ? newQuery.page : 1;
                    const c = newQuery.category ? newQuery.category : null;
                    const t = newQuery.term ? newQuery.term : null;
                    const filter = { term: t, category: c };

                    this.setFilters(filter);
                    this.setPage(p);
                    this.searchTerm = t;
                    this.fnSearchProduct();
                }

                if(JSON.stringify(newQuery) !== JSON.stringify(oldQuery) && oldQuery == null && this.products.length == 0){
                    const p = newQuery.page ? newQuery.page : 1;
                    const c = newQuery.category ? newQuery.category : null;
                    const t = newQuery.term ? newQuery.term : null;
                    const filter = { term: t, category: c };
                    this.setFilters(filter);
                    this.setPage(p);
                    this.searchTerm = t;
                    this.fnSearchProduct();
                }
            },
            immediate: true, 
            deep: true,
        },
    },
    computed : {
        ...mapGetters('CartModule',['cart']),
        ...mapGetters('AuthModule',['user']),
        ...mapGetters('ProductModule',['products','page','last_page','getPreviousQuery','total','length']),
        ...mapGetters('ProductModule',{
            ProductSearchTerm : 'searchTerm',
        }),
        searchTerm : {
            get(){
                return this.ProductSearchTerm
            },
            set(value){
                this.setSearchTerm(value)
            }
        },

        ...mapGetters('CategoryModule',
            {
                categories : 'data',
                categoryLoading : 'loading',
            }
        ),
        cartTotal : function(){
            const total = this.cart.map((item) => {
                    return unformat(item.LineTotal)
                }).reduce((a, b) => a + b, 0);
            return formatMoney(total,{ symbol: "", precision: 2, thousand: ",", decimal: "." });
        }
    }
}

</script>

<style lang="scss" scope>

.flex-item-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.flex-item-card {
  flex: 1 1 calc(33.333% - 1rem);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.card-body {
  flex: 1 1 auto; 
  display: flex;
  flex-direction: column;
}

.product-other-details {
  margin-bottom: auto; 
}

.flex-footer-card {
  margin-top: auto; 
  text-align: center;
}


@media (min-width: 900px) {
  .flex-item-card {
    max-width: calc(33.333% - 1rem); 
  }
}

@media (max-width: 768px) {
  .flex-item-card {
    flex: 1 1 calc(50% - 1rem); 
  }
}

@media (max-width: 576px) {
  .flex-item-card {
    flex: 1 1 calc(50% - 1rem); 
  }
}


</style>