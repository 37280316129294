export function bearer() {
    let user = JSON.parse(localStorage.getItem( 'nl-user'));
    if (user && user.token) {
        return { 'Authorization': 'Bearer ' + user.token };
    } else {
        return {};
    }
}

export function logout() {
    return localStorage.removeItem('nl-user');
}

export function getUserData() {
    return JSON.parse(localStorage.getItem('nl-user'));
}

export function setUserData(payload) {
    const nlUser = JSON.parse(localStorage.getItem('nl-user'));
    let newNlUser = {...nlUser, ...payload};
    return localStorage.setItem('nl-user', JSON.stringify(newNlUser));
}