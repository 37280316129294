import store from '../../store/netherlands'
export default function guest({ next, router }) {
/*   const tokenLocalStorage = JSON.parse(localStorage.getItem('user'));
    
  if (tokenLocalStorage !== null) {
    return router.push({ name: 'welcome' });
  }

  console.log("AppModule");
  store.commit('AppModule/setLayout', 'guest'); */
  store.commit('AppNetherlandsModule/setLayout','authenticated');
  return next();
}