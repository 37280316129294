import Axios from 'axios'
import { bearer } from '../helpers'
import store from '../store/netherlands';

export const netherlandsHttpService = {

    async login(params){
        return await Axios.post(`/api/nl-v1/login`, params).then(response => {
            if (response.data.token) {
                localStorage.setItem('user', JSON.stringify(response.data));
            }
            return response;
        }).catch((error) => {
            return Promise.reject(error)
        })
    },
    getRequest(endpoint = '', params = {}){
        return Axios.get(`api/nl-v1/${endpoint}`, { params: {...params }, headers : {...bearer()}}).then((response) => {
            return Promise.resolve(response)
        }).catch((error) => {
            return Promise.reject(error)
        })
    },

    postRequest(endpoint = '', params = {}){
        return Axios.post(`api/nl-v1/${endpoint}`, params, { headers: bearer() }).then((response) => {
            return Promise.resolve(response)
        }).catch((error) => {
            return Promise.reject(error)
        })
    },

    getToken(token){
        return Axios.get(`api/nl-v1/auth-token?token=${token}`).then((response) => {
            return Promise.resolve(response)
        }).catch((error) => {
            return Promise.reject(error)
        })
    },
    
    get(endpoint = '', params,configParams) {
        if(store.getters[configParams.req]) this.cancelRequest(configParams)
        return this.makeRequest(endpoint,params,configParams);
        
    },
     makeRequest(endpoint,object,configParams){
         
        const self = this;
        const axiosSource = Axios.CancelToken.source();
        store.commit(configParams.addRequest,axiosSource)


        return Axios.get(`/api/nl-v1/${endpoint}`, { params: {...object }, cancelToken: axiosSource.token, headers : {...bearer()}}).then((response) => {
            self.clearOldRequest(configParams,"Success");
            return Promise.resolve(response)
        }).catch((error) => {
            return Promise.reject(error)
        })
    },

    post(endpoint,object,configParams){
         
        const self = this;
        const axiosSource = Axios.CancelToken.source();

        if(configParams.length > 0){
            store.commit(configParams.addRequest,axiosSource)
        }

        const params = {...object}

        // console.log(params)
        return Axios.post(`api/nl-v1/${endpoint}`, params, { headers: bearer() }).then((response) => {
            
            if(configParams.length > 0){
                self.clearOldRequest(configParams,"Success");
            }
            return Promise.resolve(response)
        }).catch((error) => {

            if(configParams.length > 0){
                self.logResponseErrors(error)
            }
            return Promise.reject(error)
        })
    },

    store(endpoint,object){

        const params = {...object}
        return Axios.post(`api/nl-v1/${endpoint}`, params, { headers: bearer() }).then((response) => {
            return Promise.resolve(response)
        }).catch((error) => {
            return Promise.reject(error)
        })
    },

    upload(endpoint,formData){
        
        return Axios.post(`api/nl-v1/${endpoint}`, formData, { 
            headers: {
                'Content-Type': 'application/octet-stream',
                ...bearer(),
            }
        
        }).then((response) => {
            return Promise.resolve(response)
        }).catch((error) => {
            return Promise.reject(error)
        })
    },


    async update(endpoint, object, configParams) {
        const self = this;
        const axiosSource = Axios.CancelToken.source();

        if(configParams.length > 0){
            store.commit(configParams.addRequest,axiosSource)
        }


        const params = { ...object }
        return await Axios.put(`/api/nl-v1/${endpoint}`, params, { headers: bearer() })
            .then(response => {
                Promise.resolve(response);
                if(configParams.length > 0){
                    self.clearOldRequest(configParams,"Success");
                }
            })
            .catch(error => Promise.reject(error))
    },

    async delete(endpoint, params) {
        return await Axios.delete(`/api/nl-v1/${endpoint}`, { data : params, headers: bearer() })
            .then(response => Promise.resolve(response))
            .catch(error => Promise.reject(error))
    },

    cancelRequest(configParams){
        store.commit(configParams.cancelRequest)
    },
    
    logResponseErrors(err) {
        console.log("http error")
        console.log(err)
        if (Axios.isCancel(err)) {
            console.log("Request cancelled");
        }
    },
    clearOldRequest(configParams,msg) {
          store.commit(configParams.clearOldRequest,msg)    
    }
}