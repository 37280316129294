import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)


import { AuthModule } from './nl/auth.module'
import { ProductModule } from './nl/product.module'
import { AppNetherlandsModule } from './modules/app.netherlands.module'
import { LayoutModule } from './modules/layout.module'
import { ShowroomsModule } from './modules/showrooms.module'
import { CartModule } from './nl/cart.module'
import { CustomerModule} from './nl/customer.module'
import { UserModule} from './nl/user.module'
export default new Vuex.Store({
    modules : {
        AppNetherlandsModule,
        AuthModule,
        LayoutModule,
        ShowroomsModule,
        ProductModule,
        CartModule,
        CustomerModule,
        UserModule 
    },
    getters : {
        
    }
})