<template>
<div class="w-100">
    Checkout
      
</div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from 'vuex'
import {mdbAlert,mdbModalFooter,mdbCardFooter,mdbRow, mdbCol, mdbCard, mdbCardBody, mdbCardTitle,  mdbView, mdbCardImage,  mdbTooltip, mdbBtn, mdbModal, mdbModalBody, mdbModalHeader, mdbModalTitle} from 'mdbvue'
import unformat from 'accounting-js/lib/unformat.js'
import formatMoney from 'accounting-js/lib/formatMoney.js'
import {TextInput} from '../components/common'
import mdbAutocomplete from '../components/common/Autocomplete'
import CameraScanner from '../components/CameraScanner'
import { ValidationObserver,ValidationProvider } from "vee-validate";
import { QrcodeStream } from 'vue-qrcode-reader'
export default {
    components : {
        CameraScanner,mdbAlert,QrcodeStream,mdbModalFooter,ValidationObserver,ValidationProvider,TextInput,mdbAutocomplete,mdbRow, mdbCol, mdbCard, mdbCardBody, mdbCardFooter, mdbCardTitle,  mdbView, mdbCardImage,  mdbTooltip, mdbBtn, mdbModal, mdbModalBody, mdbModalHeader, mdbModalTitle
    },
    data : () => ({
        decodedString : null,
        decodedLoginString : null,
        checking : false,
        scanModal : false,
        modal : false,
        records: [],
        items: [],
        index: [],
        loading: false,
        product : null,
        productLoading : true,
        modalCart : false,
        modalScan : false,
        modalCustomer : false,
        customers : [],
        checkoutForm : null,
        placeOrderLoading : false,
        notfound : false,
        sales_person : "",
        perPage : 24,
        totalRecord : 24,
        current_page : 1,
        last_page : 1,
        modalLoading : false,
        modalSearch : false,
        clearSearch : false,
        searchTerm : null,
        searchTermTrue : null,
        constraints: {
            facingMode: 'user'
        },

        modalKey : 0,
        debounceCustomerTimer : null,
        processFormLoading : false
    }), 
    mounted(){
        const self = this;
        this.checkoutForm = this.customerObj();
        this.fnSearchProduct();

        this.$eventBus.$on('open-cart', function(){
            self.modalCart = true
        })
        
        this.$eventBus.$on('open-scan', function(){
            self.modalScan = true
            self.decodedString = null
            self.notfound = false
            self.checking = false
        })
        
        this.$eventBus.$on('open-filter', function(){
            self.modalSearch = true
        })

        let user = JSON.parse(localStorage.getItem('user'));  
        self.sales_person = user.user.name
    },
    methods : {
        ...mapActions('UserModule',[
            'authToken'
        ]),
        ...mapActions('CustomerModule',[
            'findCustomer'
        ]),
        ...mapActions('ProductModule',[
            'resetData','getProduct'
        ]),
        ...mapMutations('ProductModule',[
            'setPage','setSearchTerm','setFilters'
        ]),
        ...mapActions('CartModule',[
            'addToCart','getCart','updateCart','deleteItem','placeOrder','createCart'
        ]),
        isValid(isInvalid,inpt){
            return ((isInvalid === true && inpt === '') || (inpt === '' && this.validated === true)) ? 'is-invalid' : null
        },
        hasError(error){
            return (error.length > 0) ? 'is-invalid' : null
        },
        async fnSearchProduct(){
            const self = this
            self.loading = true;
            self.modalLoading = true;
            await this.resetData().then((response) => {
                console.log("fnSearchProduct")
                console.log(response)

                self.current_page = response.page
                self.last_page = response.to
                self.totalRecord = response.total
        
                self.records = response.data.map((i) => {
                    i.U_imgURL = i.U_imgURL.replace(/\/(small|thumb)-/, "/large-");
                    return i
                })
                self.decodedString = null
                self.checking = false;
                self.notfound = true;
                self.scanModal = true;
                self.loading = false;
                self.modalLoading = false;
                 if(self.searchTerm != null && self.searchTerm != ""){
                    self.searchTermTrue = true
                 }

                 if(self.clearSearch){
                    self.clearSearch = false;
                    self.searchTerm = null;
                 }

                 self.scrollToTop()
                
            })
        },

        viewGallery(v){

            if(v.image.length > 0){
                this.items = v.image.map((i) => {
                    return i.large
                });

                this.index = 0
            }
        },

        fnAddToCart(){
            const self = this;
            console.log("fn add to cart",this.product)
            this.addToCart(this.product).then(() => {
                self.$ThatsNotify.success({
                    message: "Item was succesfully added to cart"
                });

                self.modal = false
                self.modalCart = true
                            /* MessageBox({
                              title : 'Success',
                              message: 'Item successfully added to cart',
                              type: 'success'
                          }) */
            });
        },

        viewProduct(series){
            const self = this
            this.modalKey++
            this.modalLoading = true
            self.productLoading = true
            this.getProduct(series).then((response) => {
                console.log("response viewProduct",response)
                self.product = {
                    ...response,
                    U_imgURL : response.U_imgURL.replace(/\/(small|thumb)-/, "/large-")
                }
                self.productLoading = false
                self.modalScan = false
                self.modal = true
                self.modalLoading = false
                // self.getDefault()
            }).catch((err) => {

                console.log("View Product error",err)
                 self.modal = false
                self.productLoading = false
                self.checking = false
                self.notfound = true
                self.modalLoading = false
            })
        },

        incrementItem(v){
            const Quantity = v.Quantity + 1;
            v.Quantity = Quantity;
            var UnitPrice = this.$utils.unformat(v.UnitPrice);
            v.LineTotal = parseFloat(UnitPrice) * parseInt(Quantity);
            console.log("incrementItem",v)
            this.updateCart(v)
      },
      decrementItem(index,v){

         
        if(v.Quantity >= 2){

            const Quantity = v.Quantity - 1;
            v.Quantity = Quantity;

            var UnitPrice = this.$utils.unformat(v.UnitPrice);
            v.LineTotal = parseFloat(UnitPrice) * parseInt(Quantity);
            console.log("decrementItem",v)
            this.updateCart(v)
        }
      },

      fnRemoveItem(index,item){

            const self = this

            const swalWithBootstrapButtons = this.$swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-success z-depth-0',
                    cancelButton: 'btn btn-danger z-depth-0'
                },
                buttonsStyling: false
                })

                swalWithBootstrapButtons.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, delete it!',
                cancelButtonText: 'No, cancel!',
                reverseButtons: true
                }).then((result) => {
                if (result.isConfirmed) {
                    console.log("deleteItem",item)
                    self.deleteItem(item).then((res) => {

                        console.log("delete response",res)
                        swalWithBootstrapButtons.fire(
                            'Deleted!',
                            'Item has been deleted.',
                            'success'
                            )
                            self.cart.splice(index,1)
                    }).catch(() => {
                        swalWithBootstrapButtons.fire(
                        'Cancelled',
                        'Something went wrong',
                        'error'
                        )
                    })  
                    
                } else{
                    swalWithBootstrapButtons.fire(
                    'Cancelled',
                    'Action cancelled',
                    'error'
                    )
                }
                })

      },

      checkoutCart(){
        this.modalCart = false
        this.modalCustomer = true
      },

      customerObj(){
          return {
                contact_id : "",
                mobile : "",
                name : "",
                address : "",
                instructions : "",
            }
        },

      requestCustomerNumber(value){
            const self = this
            if (value === "") {
                this.customers = [];
                return;
            }
            if (this.debounceCustomerTimer) {
                clearTimeout(this.debounceCustomerTimer); 
            }

            this.debounceCustomerTimer = setTimeout(() => {
            if (value === "") {
                this.customers = [];
                return;
            }
            this.findCustomer({ term: value })
                .then((res) => {
                    console.log("findCustomer",res)
                    self.customers = res.data;
                })
                .catch((err) => {
                    console.log(err);
                    self.customers = [];
                });
            }, 300);
        },
        onSelectCustomer(v){
            const self = this;
            self.checkoutForm.CardCode = v.CardCode;
            self.checkoutForm.Cellular = v.Cellular;
            self.checkoutForm.CardName = v.CardName;
            self.checkoutForm.ShippingAddress = v.ShippingAddress;
        },
        onClear(){
            this.checkoutForm = this.customerObj();
        },
        onProcessForm(){
            const success = this.$refs.customeObserver.validate();
            if (!success) {
                return;
            }

            this.processFormLoading = true;

        },
        onPlaceOrderForm(){
          const self = this;
          self.placeOrderLoading = true;
          const success = this.$refs.customeObserver.validate();
            if (!success) {
                return;
            }

            const swalWithBootstrapButtons = this.$swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-danger'
                },
                buttonsStyling: false
                })

            if (success) {
                
                // console.log(this.checkoutForm)
                this.placeOrder(this.checkoutForm).then((response) => {
                    
                    console.log("placeOrder")
                    console.log(response)
                    swalWithBootstrapButtons.fire(
                        'Success!',
                        `Your order has been placed. Ref. # ${response.data.series}`,
                        'success'
                    )
                    self.checkoutForm = self.customerObj();
                    self.placeOrderLoading = false;
                    self.modalCustomer = false;
                });

            }
      },

        onDecode(decodedString){
            this.decodedString = decodedString;
            this.checking = true;
            this.viewProduct(decodedString)
        },

        repaint (location, ctx) {
            const {
            topLeftCorner,
            topRightCorner,
            bottomLeftCorner,
            bottomRightCorner,
            } = location

            ctx.strokeStyle = 'blue' // instead of red

            ctx.beginPath()
            ctx.moveTo(topLeftCorner.x, topLeftCorner.y)
            ctx.lineTo(bottomLeftCorner.x, bottomLeftCorner.y)
            ctx.lineTo(bottomRightCorner.x, bottomRightCorner.y)
            ctx.lineTo(topRightCorner.x, topRightCorner.y)
            ctx.lineTo(topLeftCorner.x, topLeftCorner.y)
            ctx.closePath()

            ctx.stroke()
        },

        onDecodeLogin(decodedString){
            const self = this
            this.decodedLoginString = decodedString;
             const splitQcode = this.decodedLoginString.split('-x-');
            if(splitQcode.length > 1){
                       /*  const locationToken = splitQcode[1].replace(/(\r\n|\n|\r)/gm, "");

                   
                            const params = {token : locationToken, bearer : splitQcode[0]}; */
                            this.authToken(splitQcode[0]).then((res) => {
                                console.log(res)
                                if (res.data.token) {
                                    localStorage.setItem('user', JSON.stringify(res.data));
                                }
                                self.$router.go()
                                // self.$router.push({ name: 'welcome' });
                            }).catch(error => {
                                
                            })
                    }
        },

        nextPage(){
            const nextPage = (this.current_page == this.last_page) ? this.current_page : this.current_page + 1;
            this.setPage(nextPage)
            this.fnSearchProduct()
        },

        prevPage(){
            const nextPage = (this.current_page == 1) ? this.current_page : this.current_page - 1;
            this.setPage(nextPage)
            this.fnSearchProduct()
        },

        onSearchTerm(){
           
            if(this.searchTerm != null && this.searchTerm != ""){
                
                this.setPage(1)
                this.setSearchTerm(this.searchTerm)
                const filter = {"searchTerm":this.searchTerm,"supplierCode":null,"itemName":null,"ItmGroup":"0","brands":"0","collection":"0","category":["0"],"materials":"ALL","colour":"ALL","attribute":"ALL","status":"all","website":"all","availability":"0","in_transit":"0","under_production":"0","range":[-9999,9999]}
                this.setFilters(filter)
                this.modalSearch = false;
                this.fnSearchProduct()
                
            }
        },

        onClearSearch(){
            this.clearSearch = true
            this.setPage(1)
            this.setSearchTerm(null)
            const filter = {"searchTerm":null,"supplierCode":null,"itemName":null,"ItmGroup":"0","brands":"0","collection":"0","category":["0"],"materials":"ALL","colour":"ALL","attribute":"ALL","status":"all","website":"all","availability":"0","in_transit":"0","under_production":"0","range":[-9999,9999]}
            this.setFilters(filter)
            this.modalSearch = false;
            this.fnSearchProduct()
        },
        scrollToTop() {
            window.scrollTo({
                top: 0,
                behavior: 'smooth', 
            });
        },

        fnFormatMoney(v){
            return formatMoney(v,{ symbol: "", precision: 2, thousand: ",", decimal: "." });
        },

        clearCart(){

            const self = this

            const swalClearCart = this.$swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-success z-depth-0',
                    cancelButton: 'btn btn-danger z-depth-0'
                },
                buttonsStyling: false
                });

                swalClearCart.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, clear it!',
                cancelButtonText: 'No, cancel!',
                reverseButtons: true
                }).then((result) => {
                if (result.isConfirmed) {
                    
                    self.createCart().then((res) => {

                        console.log("create response",res)
                        swalClearCart.fire(
                            'Created!',
                            'Cart has been created.',
                            'success'
                            )
                    }).catch(() => {
                        swalClearCart.fire(
                        'Cancelled',
                        'Something went wrong',
                        'error'
                        )
                    })  
                    
                } else{
                    swalClearCart.fire(
                    'Cancelled',
                    'Action cancelled.',
                    'error'
                    )
                }
                })
        },

        handleModalHide(event){
            console.log(event)
        }
    },
    computed : {
        ...mapGetters('CartModule',['cart']),
        cartTotal : function(){
            const total = this.cart.map((item) => {
                    return unformat(item.LineTotal)
                }).reduce((a, b) => a + b, 0);
            return formatMoney(total,{ symbol: "", precision: 2, thousand: ",", decimal: "." });
        }
    }
}

</script>

<style lang="scss" scope>

.flex-item-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.flex-item-card {
  flex: 1 1 calc(33.333% - 1rem);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.card-body {
  flex: 1 1 auto; 
  display: flex;
  flex-direction: column;
}

.product-other-details {
  margin-bottom: auto; 
}

.flex-footer-card {
  margin-top: auto; 
  text-align: center;
}


@media (max-width: 768px) {
  .flex-item-card {
    flex: 1 1 calc(50% - 1rem); 
  }
}

@media (max-width: 576px) {
  .flex-item-card {
    flex: 1 1 calc(50% - 1rem); 
  }
}


</style>