<template>
    <ValidationObserver
        class="needs-validation form-custom-format"
        v-bind:class="{'was-validated': validated}"
        tag="form"
        ref="observer"
        v-slot="{passes}"
        @submit.prevent="onSubmitForm"
    >
        <el-dialog
            :modal="true"
            ref="window"
            :fullscreen="true"
            :title="'Sync Item Master'"
            :visible.sync="syncModal"
            :close-on-click-modal="false"
            :modal-append-to-body="true"
            :append-to-body="true"
            class="text-left bulk-update-qty-dialog"
            :formStatus="formStatus"
            @dialog-state="dialogState"
            :group="group"
            :showClose="!processing"
        >

        <div class="d-inline-flex p-1">
            <el-checkbox v-model="databaseCheckedOptions.thatsliving" disabled border style="border-color: #409EFF;" class="mr-1">THATSLIVING</el-checkbox>
            <el-checkbox v-model="databaseCheckedOptions.tlint" border>TLINT</el-checkbox>
        </div>

            <template v-if="formStatus == 'select'">
                <el-row :gutter="20">
                    <el-col :span="12" :offset="6" class="mt-4 mb-2">
                        <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange" border size="medium">CHECK ALL TEST</el-checkbox>
                    </el-col>
                    <el-checkbox-group v-model="checkedOptions" @change="handleCheckedOptionsChange">
                        <el-col :span="12" :offset="6" v-for="obj in checkboxOptions" class="mt-1" :key="obj.value">
                            <el-checkbox :label="obj.value" :key="obj.value" border size="medium">{{obj.label.toUpperCase()}}</el-checkbox>
                        </el-col>
                    </el-checkbox-group>
                    
                    
                    
                </el-row>
            </template>

            

            <div ref="spreadsheet"></div>
            <template #footer>
                <div class="d-flex">
                    <template v-if="formLoading">
                        <mdb-btn color="elegant" size="sm" disabled>
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            Loading...
                        </mdb-btn>
                    </template>
                    <template v-else>
                        <template v-if="formStatus == 'select'">
                        <mdb-btn color="elegant" size="sm" @click="passes(onSubmitSelectForm)" :disabled="(checkedOptions.length > 0 ? false : true)"><i class="el-icon-right text-white cursor-pointer"></i> Next</mdb-btn>
                        </template>
                        <template v-if="formStatus == 'create'">
                            <template v-if="processing == true">
                                <mdb-btn color="elegant" size="sm" disabled>
                                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    Processing...
                                </mdb-btn>
                            </template>
                            <template v-else>
                                <mdb-btn color="elegant" size="sm" @click="passes(onSubmitForm)">
                                    <i class="el-icon-check text-white cursor-pointer"></i> Sync
                                </mdb-btn>
                                <mdb-btn color="grey" size="sm" @click="passes(onSubmitBack)"><i class="el-icon-back text-white cursor-pointer"></i> Back</mdb-btn>
                            </template>
                            <mdb-btn color="grey" size="sm" @click="passes(clearForm)">
                                <i class="el-icon-copy-document text-white cursor-pointer"></i> Clear
                            </mdb-btn>
                        </template>
                        <mdb-btn color="grey" size="sm" @click="closeDialog">
                            <i class="el-icon-close text-white cursor-pointer"></i> Close
                        </mdb-btn>
                    </template>
                </div>
            </template>
        </el-dialog>
    </ValidationObserver>
</template>
<script>
import { mdbBtn} from "mdbvue";

import {ValidationObserver, ValidationProvider} from "vee-validate";
import { mapActions,mapGetters, mapMutations } from 'vuex'

import {  mdbRow,mdbCol } from 'mdbvue';
import jspreadsheet from '../../jsexcel/jexcel'
import { drop } from "lodash";

import { MessageBox, Message } from "element-ui";

import  {ThatsDialog, ThatsInput} from '../../components/thats/Thats'

const dropdownBrandFilter = function(instance, cell, c, r, source) {

    console.log('source',source)
    var value = instance.jexcel.getValueFromCoords(c - 1, r);
    const filterResult = source.filter((item)=>{
        return item.parent == value
    },[]);
    return filterResult
}


const dropdownCategoryFilter = function(instance, cell, c, r, source) {

    console.log('source',source)
    var value = instance.jexcel.getValueFromCoords(c - 1, r);
    const filterResult = source.filter((item)=>{
        return item.area == value
    },[]);
    return filterResult
}

const dropdownCollectionFilter = function(instance, cell, c, r, source) {

    console.log('source',source)
    var value = instance.jexcel.getValueFromCoords(c - 1, r);
    const filterResult = source.filter((item)=>{
        return item.area == value
    },[]);
    return filterResult
}

export default {
    components: { ValidationProvider,
        mdbBtn,
        ValidationObserver,
        mdbRow,
        mdbCol,
        ThatsDialog,
        ThatsInput
    },
    props : {
        group : {
            type : Number,
            default : 1
        },
    },

    data(){

    const self = this;
    return {
        formStatus : 'select',
        syncModal : false,
        validated: false,
        form : null,
        formData:null,
        formDataOrginal : null,
        formLoading : false,
        processing : false,
        showError : false,
        group2 : 3,
        spreadsheet: null,
        catalogName: null,
        exportTableOptions : {
            download : false,
            allowDeleteColumn : false,
            allowRenameColumn : false,
            defaultColAlign : 'left',
            csvHeader:true,
            tableWidth : '100%',
            tableHeight : 'calc(100vh - 125px)',
            tableOverflow : true,
            data:[[]],
            minDimensions:[3,50],
            onCopy : null,
            selectionCopy : false,
            allowCopy : true,
            columns: [

                {
                    type: 'text',
                    width: '200px',
                    title: 'Item Code',
                    name: 'series',
                    readOnly: false,
                    primaryKey: false
                },
                {
                    type: 'text',
                    width: '80px',
                    title: 'Photo',
                    name: 'photo',
                    readOnly: false
                },
               {
                    type: 'text',
                    width: '200px',
                    title: 'Item Name',
                    name: 'name',
                    readOnly: false,
                },

                 {
                    type: 'text',
                    width: '200px',
                    title: 'Price',
                    name: 'price',
                    readOnly: false,
                },
                 {
                    type: 'text',
                    width: '200px',
                    title: 'PurchaseUomDimension',
                    name: 'PurchaseUomDimension',
                    readOnly: false,
                },
                 {
                    type: 'text',
                    width: '200px',
                    title: 'SalesUomDimension',
                    name: 'SalesUomDimension',
                    readOnly: false,
                },

                

            ],
/* 

{ label: 'Price', value: 'sale_price', width : '150px', dropdown: false, type : 'numeric'},
            { label: 'PurchaseUomDimension', value: 'PurchaseUomDimension', width : '150px', dropdown: false, type : 'text'},
            { label: 'SalesUomDimension', value: 'SalesUomDimension', width : '150px', dropdown: false, type : 'text'},
             
             */
            updateTable:function(instance, cell, col, row, val, label, cellName) {
                if((row % 2) == 1){
                    cell.parentNode.style.backgroundColor = '#FAFAFA';
                }

                if (col == 1) {

                  if(val != null && val != ''){
                    cell.innerHTML = '<img src="' + val + '" style="width:60px;height:60px">';
                  }else{
                    cell.innerHTML = '<img src="https://media.thatsliving.com/public/uploads/image-png/full-e271b992b2be985538ba519189106b1f-tl100.png" style="width:60px;height:60px">';
                  }
                }

            },

            contextMenu: function(obj, x, y, e) {

                return false;
            },
            onchange: function (domElement, domElmentCell, column, row, value, oldValue) {
                if (column == 0) {
             
                    if ((value != null && value != '' && value != oldValue) || (oldValue == '' && oldValue == null)) {
                        self.fnGetItem(value,row, column)
                    }
                }
                /* if (column == 1) {
             
                    if ((value != null && value != '' && value != oldValue) || (oldValue == '' && oldValue == null)) {
                        self.fnGetItem(value,row,column )
                    }
                } */
            },
        },

        isIndeterminate: false,
        checkAll: true,
        databaseCheckedOptions: {
            thatsliving : true,
            tlint : false,
        },

         checkboxDatabaseOptions : [
            { label: 'THATSLIVING', value: 'THATSLIVING'},
            { label: 'TLINT', value: 'TLINT'},
         ],

         

        checkedOptions: [
            // 'opening_qty',
            // 'stock_qty',
            // 'sold_qty',
            // 'in_transit_qty',
            // 'under_production_qty',
            'series',
            'picture',
            'name',
            'price',
            'PurchaseUomDimension',
            'SalesUomDimension'
        ],
        checkboxOptions : [
            { label: 'Picture', value: 'picture', width : '200px', dropdown : false, type : 'text'},
            { label: 'Name', value: 'name', width : '150px', dropdown: false, type : 'text'},
            { label: 'PurchaseUomDimension', value: 'PurchaseUomDimension', width : '150px', dropdown: false, type : 'text'},
            { label: 'SalesUomDimension', value: 'SalesUomDimension', width : '150px', dropdown: false, type : 'text'},
            // { label: 'Stock', value: 'stock_qty', width : '100px', dropdown : false, type : 'text'},
            // { label: 'Sold', value: 'sold_qty', width : '100px', dropdown : false, type : 'text'},
            // { label: 'In Transit', value: 'in_transit_qty', width : '100px', dropdown : false, type : 'text' },
            // { label: 'Under Production', value: 'under_production_qty', width : '100px', dropdown : false, type : 'text' },
            /* { label: 'Group', value: 'group', width : '100px', dropdown: true, type : 'dropdown', source : 'groupComputed'},
            { label: 'Brand', value: 'brand', width : '100px', dropdown: true, type : 'dropdown', source : 'brandComputed'},
            { label: 'Area', value: 'area', width : '100px', dropdown: true, type : 'dropdown', source : 'areaComputed'},
            { label: 'Category', value: 'category', width : '100px', dropdown: true, type : 'dropdown', source : 'categoryComputed'},
            { label: 'Collection', value: 'collection', width : '100px', dropdown: true, type : 'dropdown', source : 'collectionComputed'},
            { label: 'Item Status', value: 'status', width : '100px', dropdown: true, type : 'dropdown', source : [
                    {name : "Archive", id : "Archive"},
                    {name : "Active", id : "Active"},
                    {name : "Discontinued", id : "Discontinued"},
                ]},
            { label: 'Item Type', value: 'item_type', width : '150px', dropdown: true, type : 'dropdown', source : [
                    {name : "Stock", id : "Stock"},
                    {name : "Service", id : "Service"},
                ]},
            { label: 'Web Live Status', value: 'website', width : '150px', dropdown: true, type : 'dropdown', source : [
                    {name : "Yes", id : "Yes"},
                    {name : "No", id : "No"},
                ]},
            { label: 'Price', value: 'sale_price', width : '150px', dropdown: false, type : 'numeric'}, */
        ],

        postedData : [],
        }
    },
    inject : ['brandProvider','categoryProvider','areaProvider','collectionProvider'],
    created(){
        this.formData = JSON.parse(JSON.stringify(this.formObject))
        this.formDataOrginal = JSON.parse(JSON.stringify(this.formObject))
    },
    mounted() {

    },
    methods: {
        ...mapActions('ItemModule',['fetchItem']),
        ...mapActions('InventoryItemMasterModule',['storeRecord','syncItemMaster']),
        createPopupTable(exportTableOptions){
            this.spreadsheet = jspreadsheet(this.$refs.spreadsheet, exportTableOptions);
            Object.assign(this, this.spreadsheet);
            this.spreadsheet.setData([])
        },

        async recreateSpreadsheet(){
            await this.spreadsheet.destroy();
        },
        /* start */



        optionsHandler(){
            var self = this;
        },

async fnGetItem(itemCode, index, col){
        const self = this;
        await this.fetchItem({item : itemCode, index : index}).then((res)=> {
            const row = parseInt((index + 1))

            const lastCol = self.exportTableOptions.columns.length - 1;

            console.log("lastCol",lastCol)

              const itemId = self.spreadsheet.getValueFromCoords(lastCol,index)

            let itemIdString = res.id.toString()
            if(col == 0){
                self.spreadsheet.setValueFromCoords(0,index,res.itemCode,true)
                self.spreadsheet.setValueFromCoords(1,index,res.photo,true)

                let selectedItem = {};

                selectedItem.series = res.series;
                selectedItem.picture = res.photo;
                selectedItem.name = res.name;

                const itemAttr = res.attr;

                if(itemAttr.hasOwnProperty("ItemUnitOfMeasurementCollection")){
                    const ItemUnitOfMeasurementCollection = itemAttr.ItemUnitOfMeasurementCollection
                    if(ItemUnitOfMeasurementCollection.hasOwnProperty("PurchaseUomDimension")){
                        selectedItem = {...selectedItem,...ItemUnitOfMeasurementCollection.PurchaseUomDimension}
                    }
                    if(ItemUnitOfMeasurementCollection.hasOwnProperty("SalesUomDimension")){
                         selectedItem = {...selectedItem,...ItemUnitOfMeasurementCollection.SalesUomDimension}
                    }
                }

                console.log("self.exportTableOptions.columns",selectedItem )

                const updatedColumns = JSON.parse(JSON.stringify(self.exportTableOptions.columns)).slice(2); 
                console.log("updatedColumns",updatedColumns )
                let ctr = 2;

                const objKeys = Object.keys(selectedItem);

                updatedColumns.forEach(element => {

                    if(objKeys.includes(element.name)){
                        self.spreadsheet.setValueFromCoords(ctr,index,selectedItem[element.name],true)
                    }
                    ctr = ctr + 1;
                });

                




                // self.spreadsheet.setValueFromCoords(2,index,res.photo,true)

                
                self.spreadsheet.setValueFromCoords(lastCol,index,itemIdString,true)

                if(res.photo === null){
                    self.spreadsheet.setValueFromCoords(lastCol - 1,index,"NO PHOTO AVAILABLE",true);
                    throw new Error('No Photo Available');
                }
/* 
                if(itemId){

                    const splitId = itemId.split('-');
    
                    console.log("splitId",splitId)
                    if(splitId.length > 1){
                        itemIdString = `${itemIdString}-${splitId[1]}`
                    }
                }

                console.log("itemIdString",itemIdString)
                
                self.spreadsheet.setValueFromCoords(6,index,itemIdString) */
            }

           /*  if(col == 1){

                const splitId = itemId.split('-');

                console.log("splitId",splitId)
                if(splitId.length > 0){
                    itemIdString = `${splitId[0]}-${res.id}`
                }


                self.spreadsheet.setValueFromCoords(6,index,itemIdString)
            } */
            const parseIntIndex = parseInt(index)

            const backgroundColor = 'transparent'
            self.spreadsheet.setStyle(`A${parseIntIndex+1}`, 'background-color', backgroundColor)
            self.spreadsheet.setStyle(`B${parseIntIndex+1}`, 'background-color', backgroundColor)
            self.spreadsheet.setStyle(`C${parseIntIndex+1}`, 'background-color', backgroundColor)
            self.spreadsheet.setStyle(`D${parseIntIndex+1}`, 'background-color', backgroundColor)
            /* self.spreadsheet.setStyle(`E${parseIntIndex+1}`, 'background-color', backgroundColor)
            self.spreadsheet.setStyle(`F${parseIntIndex+1}`, 'background-color', backgroundColor) */

        }).catch((error) => {
            console.log("error")
            console.log(error)
          const parseIntIndex = parseInt(index)
          let backgroundColor = 'rgb(255 200 200)';
          if(( parseIntIndex % 2) == 1){
            backgroundColor = 'rgb(233 167 167)';
          }
            self.spreadsheet.setStyle(`A${parseIntIndex+1}`, 'background-color', backgroundColor)
            self.spreadsheet.setStyle(`B${parseIntIndex+1}`, 'background-color', backgroundColor)
            self.spreadsheet.setStyle(`C${parseIntIndex+1}`, 'background-color', backgroundColor)
            self.spreadsheet.setStyle(`D${parseIntIndex+1}`, 'background-color', backgroundColor)
          /*   self.spreadsheet.setStyle(`E${parseIntIndex+1}`, 'background-color', backgroundColor)
            self.spreadsheet.setStyle(`F${parseIntIndex+1}`, 'background-color', backgroundColor) */

        })
    },

        /* end */

        openDialog(){
            this.syncModal = true
            this.formResetter();
            this.formStatus = 'select'
            /* */
        },
        closeDialog(){
            this.syncModal = false
            this.formResetter();
        },

        onSubmitCopyTemplate(){
            const self = this;

            const selectedColumns = this.checkboxOptions.filter((item) => {
                return self.checkedOptions.includes(item.value)
            },[])

            var txt = "Series\t";
            if(selectedColumns.length > 0){
                selectedColumns.forEach(element => {
                    txt += element.label+"\t"
                });
                txt += "\n"
            }

            var dummy = document.createElement("textarea");
            document.body.appendChild(dummy);
            dummy.setAttribute("id", "dummy_id");
            document.getElementById("dummy_id").value=txt;
            dummy.select();
            document.execCommand("copy");
            document.body.removeChild(dummy);

            Message.success({
                message: "Copied!"
            });
        },

        onSubmitSelectForm() {
            const self = this;

            this.formResetter();

            this.formStatus = 'create';
            let tableCols = this.checkboxOptions.filter((obj) => {
                return this.checkedOptions.includes(obj.value)
            },[]).map((obj) => {
                const dropdownObject = {}
                const numericObject = {}



                if(obj.hasOwnProperty('dropdown') && obj.dropdown == true){
                    const fromComputed = ['brandComputed','categoryComputed','areaComputed','groupComputed'];
                    dropdownObject.source = fromComputed.includes(obj.source) ? this[`${obj.source}`] : obj.source;
                    dropdownObject.autocomplete = true;
                    dropdownObject.multiple = false;
                    if(obj.source == 'brandComputed'){
                        dropdownObject.filter = dropdownBrandFilter
                    }
                    if(obj.source == 'categoryComputed'){
                        dropdownObject.filter = dropdownCategoryFilter
                    }

                }

                if(obj.type == 'numeric'){
                    numericObject.mask = '#,##.00';
                    numericObject.width = 100;
                    numericObject.decimal = '.';
                }
                return {
                    type: obj.type,
                    width: obj.width,
                    title: obj.label,
                    name: obj.value,
                    readOnly: false,
                    ...dropdownObject,
                    ...numericObject
                }
            });

/* 
            const finalColumns = [
                { type: 'text', width: '200px', title: 'Item Code', name: 'series', readOnly: false, primaryKey: false },
                { type: 'text', width: '80px', title: 'Photo', name: 'photo', readOnly: false, primaryKey: false },
                ...tableCols,
                { type: 'text', width: '340px', title: 'Response', name: 'message', readOnly: false },
                { type: 'hidden', name: 'item_id' },
            ] */

            
            const hasPurchaseColumn = tableCols.filter((i) => i.name === 'PurchaseUomDimension',[]);
            
            if(hasPurchaseColumn.length > 0){
                tableCols = [...tableCols,
                        { title: 'PurchaseLength', name: 'PurchaseUnitLength', width : '150px', dropdown: false, type : 'numeric'},
                        { title: 'PurchaseUnitWidth', name: 'PurchaseUnitWidth', width : '150px', dropdown: false, type : 'numeric'},
                        { title: 'PurchaseUnitHeight', name: 'PurchaseUnitHeight', width : '150px', dropdown: false, type : 'numeric'},
                        { title: 'PurchaseUnitVolume', name: 'PurchaseUnitVolume', width : '150px', dropdown: false, type : 'numeric',readOnly : true},
                        { title: 'PurchaseUnitWeight', name: 'PurchaseUnitWeight', width : '150px', dropdown: false, type : 'numeric'}
                    ]
            }

       

            const hasSalesColumn = tableCols.filter((i) => i.name === 'SalesUomDimension',[]);
            if(hasSalesColumn.length > 0){
                tableCols = [...tableCols,
                    { title: 'SalesLength', name: 'SalesUnitLength', width : '150px', dropdown: false, type : 'numeric'},
                    { title: 'SalesUnitWidth', name: 'SalesUnitWidth', width : '150px', dropdown: false, type : 'numeric'},
                    { title: 'SalesUnitHeight', name: 'SalesUnitHeight', width : '150px', dropdown: false, type : 'numeric'},
                    { title: 'SalesUnitVolume', name: 'SalesUnitVolume', width : '150px', dropdown: false, type : 'numeric',readOnly : true},
                    { title: 'SalesUnitWeight', name: 'SalesUnitWeight', width : '150px', dropdown: false, type : 'numeric'}
                ]
            }

            const filteredColumns = tableCols.filter((i) =>  i.name !== 'SalesUomDimension' && i.name !== 'PurchaseUomDimension',[]);
            
            
            const finalColumns = [
                { type: 'text', width: '200px', title: 'Series', name: 'series', readOnly: false, primaryKey: false },
                { type: 'text', width: '80px', title: 'Photo', name: 'photo', readOnly: false, primaryKey: false },
                ...filteredColumns,
                { type: 'text', width: '340px', title: 'Response', name: 'message', readOnly: false },
                { type: 'hidden', name: 'item_id' },
            ]



            this.$nextTick(() => {
                const width = this.$refs.window.width;
                this.exportTableOptions.tableWidth = "100%";
                this.exportTableOptions.columns = finalColumns;
                this.createPopupTable(this.exportTableOptions);
            });
        },

        onSubmitBack(){
            const self = this;
            MessageBox.confirm('Are you sure you want to go back?', 'Warning', {
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                type: 'warning'
            }).then(() => {

                self.formStatus = 'select';
                self.form = self.formVar()
                requestAnimationFrame(() => {
                    self.$refs.observer.reset();
                });
                self.validated = false
                self.formLoading = false
                if(self.spreadsheet){
                    self.spreadsheet.destroy();
                }

            }).catch(() => {
                return
            });
        },

        onSubmitForm() {
            const self = this;

            if (this.processing) {
                return false;
            }

            const tableData = this.spreadsheet.getJson();

            MessageBox.confirm('Warning: Are you sure you want to submit the data?', 'Warning', {
                confirmButtonText: 'OK',
                cancelButtonText: 'Cancel',
                type: 'warning'
            }).then(() => {
                const db = this.databaseCheckedOptions;
                if (tableData.length > 0) {
                    this.postCounter = 0;
                    this.dataLength = tableData.filter(item => item.series !== '').length;

                    if (this.dataLength > 0) {
                        const validData = tableData.filter(item => item.series !== '').map(item => {
                            return { ...item, completed: false };
                        });

                        if (validData.length === 0) {
                            self.$ThatsNotify.warning({
                                message: `Please fill up the series column.`,
                                timeOut: 10000
                            });
                            return false;
                        }

                         const columns = self.iterateAlphabetByNumber((self.exportTableOptions.columns.length))

                         console.log("columns",columns)
                        self.postedData = JSON.parse(JSON.stringify(validData));
                        validData.forEach((element, forIndex) => {
                            const row = 1 + forIndex
                            self.processing = true;

                            columns.forEach((col) => {
                                self.spreadsheet.setStyle(`${col}${row}`,'background-color','#fff');
                            })

                            self.spreadsheet.setValue(`${columns[columns.length - 2]}${row}`,"Please wait...");

                            self.syncItemMaster({...element,index : row, db}).then((response) => {
                                const i = parseInt(response.index);
                                columns.forEach((col) => {
                                    self.spreadsheet.setStyle(`${col}${i}`,'background-color','#00c851');
                                })
                                self.spreadsheet.setValue(`${columns[columns.length - 2]}${i}`,response.message);
                                self.postCounter++
                                self.$set(self.postedData[i-1],'completed',true)
                            }).catch((error) => {

                                console.log("error",error.message)
                                const i = parseInt(error.response.data.index)
                                columns.forEach((col) => {
                                    self.spreadsheet.setStyle(`${col}${i}`,'background-color','#fb3');
                                })
                                self.spreadsheet.setValue(`${columns[columns.length - 2]}${i}`,error.message || error.response.data.message);
                                this.postCounter++;
                                self.$set(self.postedData[i-1],'completed',true)
                            });
                        });
                    }
                }
            }).catch(() => {
                // Handle the case where the user cancels the confirmation dialog
                console.log('Confirmation dialog was cancelled.');
            });
        },


        formResetter(){
            this.form = this.formVar()
            requestAnimationFrame(() => {
                this.$refs.observer.reset();
            });
            this.validated = false
            this.formLoading = false
            this.processing = false
            // this.setFormStatusCreate();

            if(this.spreadsheet){
                this.spreadsheet.destroy();
            }
        },

        dialogState(state){
            this.optionsHandler();
            if(!state){
                this.formResetter();
                return
            }
            if(this.FormStatus == 'create'){
                this.formResetter();
            }

        },

        formVar(){
            return {
                items : [],
            }
        },
        setFormStatusCreate(){
            this.formStatus = 'create'
        },

        handleCheckAllChange(val) {
            this.checkedOptions = val ? this.checkboxOptions.map(i => i.value,[]) : [];
            this.isIndeterminate = false;
        },
        handleCheckedOptionsChange(value) {
            let checkedCount = value.length;
            this.checkAll = checkedCount === this.checkboxOptions.length;
            this.isIndeterminate = checkedCount > 0 && checkedCount < this.checkboxOptions.length;
        },
        
        handleDatabaseCheckedOptionsChange(value) {
            /* let checkedCount = value.length;
            this.checkAll = checkedCount === this.checkboxOptions.length;
            this.isIndeterminate = checkedCount > 0 && checkedCount < this.checkboxOptions.length; */
        },

        iterateAlphabetByNumber(numColumns) {
            const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
            const columns = [];

            for (let i = 0; i < numColumns; i++) {
                let label = '';
                let index = i;
                while (index >= 0) {
                    label = alphabet[index % 26] + label;
                    index = Math.floor(index / 26) - 1;
                }

                columns.push(label);
            }
            return columns;
        },
        clearForm(){
            this.recreateSpreadsheet();
            this.createPopupTable(this.exportTableOptions);
        },
    },

    computed: {
        ...mapGetters('InventoryItemMasterModule',{
            formRecord : 'formData',
            viewLoading : 'getLoading',
            postLoading : 'postLoading',
            formObject : 'initialFormObject',
        }),

        checkedOptionsComputed : function(){
            return (this.checkedOptions.length > 0) ? true : false;
        },

        brandComputed : function(){
            return [];
        },
        groupComputed : function(){
            return [];
        },

        categoryComputed : function(){
            return [];
        },
        areaComputed : function(){
            return [];
        },

        collectionComputed : function(){
            return [];
        },

        enableButton : function(){
            return (this.areaComputed.length > 0 && this.brandComputed.length > 0 && this.categoryComputed.length > 0) ? false : true;
        },

        isAllCompleted : function(){
            return (this.postedData.length > 0) ? this.postedData.every((item) => item.completed == true) : false;
        }
    },

    watch: {

        brandComputed : function(v){
            this.filteredBrands = v
        },
        syncModal(val) {
            if (val && this.formStatus === 'select') {
                // this.onSubmitSelectForm();
            }
        },
        isAllCompleted : function(value){
            if(value == true){
                this.processing = false;
                this.$emit('success');
            }
        },

        formStatus : function(v){
            console.log(v)
        }
    },


}
</script>

<style scoped>
.custom-checkbox .custom-control-label::before {
    border-radius: .50rem!important;cursor: pointer;
}

.no-image-gallery i.far {
    font-size: 42px;
    color: #959595;
}
.mdb-time-picker .clockpicker-span-hours,
.mdb-time-picker .clockpicker-span-minutes {
    color: #4285f4 !important;
}


</style>
