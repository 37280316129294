<template>
<div :class="'classic-form-page ' + $attrs.class">
   <mdb-view class="d-flex justify-content-center align-items-center">
       <mdb-container>
          <mdb-row>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 mx-auto">
              <mdbCard class="z-depth-0 border border-light">
                  <mdbCardBody>
                  <div class="row">
                            <div class="col-12 col-sm-12 mx-auto text-center">
                              <!-- <img src="@/assets/images/tl-cms.png" class="brand-logo"/> -->

                              <h1 class="text-center mt-2 mb-4"><strong>TL</strong> | NETHERLANDS</h1>
                            </div>
                    </div>
                  <div class="row">
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mx-auto">
                            <slot></slot>
                        </div>
                  </div>
                  </mdbCardBody>
              </mdbCard>
              </div>
          </mdb-row>
        </mdb-container>  
    </mdb-view>
</div>
</template>
<script>
import { mdbView, mdbBtn, mdbContainer, mdbRow, mdbCard, mdbCardBody, mdbCardHeader, } from "mdbvue";
export default {
    components: {
        mdbView, mdbBtn, mdbContainer, mdbRow, mdbCard, mdbCardBody, mdbCardHeader,
    },
    data : () => ({
        
    })
}
</script>


<style scoped>

.brand-logo{
  width: 100%;margin: 0 auto;
}


</style>
